// Core
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// Actions
import { adminActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { AddAdvisor, ParentsPopup } from '../Popups'
import { ConfirmationPopup, Loader, NoDataComponent, CustomPagination } from '../../GeneralComponents'
import { SetApiModal } from './SetApiKey'
import { InputBase } from '@mui/material'
import { Table } from '../../Applies/Table'
// Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
import { ReactComponent as AddUserIcon } from '../../../theme/assets/icons/add_user_white.svg'
//Utils
import { paginateArray } from '../../../utils'

/**
 * Component for managing and displaying advisors with various actions.
 * @returns {JSX.Element} AdvisorsTab component.
 */
export const AdvisorsTab = () => {
  const dispatch = useDispatch()

  // State variables
  const loading = useSelector((state) => state.general.loading) // Loading state from Redux
  const user = useSelector((state) => state.auth.user) // User state from Redux
  const allAdvisors = useSelector((state) => state.admin.allAdvisors) // All advisors state from Redux

  // Local component state using useState
  const [refetch, setRefetch] = useState(false) // State for triggering data refetch
  const [advisors, setAdvisors] = useState([]) // State for storing advisors data

  const [isOpenConfirmDeletationPopup, setIsOpenConfirmDeletationPopup] = useState(false) // State for confirm deletion popup
  const [isSetAPIKeyOpen, setIsSetAPIKeyOpen] = useState(false) // State for API key modal
  const [isOpenAddAdvisor, setIsOpenAddAdvisor] = useState(false) // State for add advisor popup
  const [isOpenParentsPopup, setIsOpenParentsPopup] = useState(false) // State for parents popup

  const [selectedAdvisor, setSelectedAdvisor] = useState(undefined) // State for selected advisor
  const [parents, setParents] = useState([]) // State for parent information

  const [currentPage, setCurrentPage] = useState(1) // State for current pagination page

  /**
   * Opens the modal to set API key for an advisor.
   * @param {string} id - Advisor ID.
   */
  const handleOpenAPIKey = (id) => {
    setSelectedAdvisor(id)
    setIsSetAPIKeyOpen(true)
  }

  /**
   * Closes the API key modal.
   */
  const handleCloseAPIKey = () => {
    setIsSetAPIKeyOpen(false)
    setRefetch((prev) => !prev)
  }

  /**
   * Opens the confirmation popup for advisor deletion.
   * @param {string} advisorId - Advisor ID.
   */
  const handleOpenDeletationPopup = (advisorId) => {
    setSelectedAdvisor(advisorId)
    setIsOpenConfirmDeletationPopup(true)
  }

  /**
   * Handles the deletion of an advisor.
   */
  const handleDeleteAdvisor = () => {
    if (user.token) {
      const data = {
        id: selectedAdvisor,
        token: user.token,
      }
      dispatch(adminActions.deleteAdvisor(data)).then((res) => {
        setIsOpenConfirmDeletationPopup(false)
        setRefetch((prev) => !prev)
      })
    }
  }

  /**
   * Closes the confirmation popup for advisor deletion.
   */
  const handleCloseDeletationPopup = () => setIsOpenConfirmDeletationPopup(false)

  /**
   * Opens the popup to add a new advisor.
   */
  const handleOpenAddAdvisorPopup = () => setIsOpenAddAdvisor(true)

  /**
   * Closes the popup to add a new advisor.
   */
  const handleCloseAddAdvisorPopup = () => setIsOpenAddAdvisor(false)

  /**
   * Opens the popup to display parents associated with an advisor.
   * @param {Array} parents - List of parents.
   * @param {Object} advisor - Selected advisor details.
   */
  const handleOpenParentsPopup = (parents, advisor) => {
    const selectedAdvisor = {
      full_name: advisor?.full_name,
      email: advisor?.email,
    }
    setParents(parents)
    setSelectedAdvisor(selectedAdvisor)
    setIsOpenParentsPopup(true)
  }

  /**
   * Closes the popup displaying parents associated with an advisor.
   */
  const handleCloseParentsPopup = () => {
    setIsOpenParentsPopup(false)
    setParents([])
    setSelectedAdvisor(0)
  }

  /**
   * Fetches all advisors when `refetch` or `user` changes.
   */
  useEffect(() => {
    if (user.token) {
      const data = {
        token: user.token,
      }
      dispatch(adminActions.getAllAdvisors(data))
    }
  }, [refetch, user])

  /**
   * Filters and paginates advisors based on `allAdvisors` and `currentPage`.
   */
  useEffect(() => {
    if (user?.user?.user_type === 'supervisor') {
      // Filters advisors for supervisors
      return setAdvisors([...allAdvisors.filter((item) => item.is_completed_registration === true && item.user.user_type === 'advisor')])
    }

    // Paginates advisors for other user types
    const paginatedData = paginateArray(allAdvisors)
    if (paginatedData.length) {
      setAdvisors([...(paginatedData[currentPage - 1] || [])])
    } else {
      setAdvisors([])
    }
  }, [allAdvisors, currentPage])

  /**
   * Resends invitation email to the advisor.
   * @param {string} id - Advisor ID.
   */
  const resendEmail = (id) => {
    if (user.token) {
      const data = {
        credentials: {
          advisor_id: id,
        },
        token: user.token,
      }
      dispatch(adminActions.resendAdvisor(data))
    }
  }

  /**
   * Handles input change for advisor search.
   * @param {Event} e - Input change event.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()
    const filteredArray = allAdvisors.filter((advisor) => advisor.user.full_name.toLowerCase().includes(inputValue) || advisor.user.email.toLowerCase().includes(inputValue))
    setAdvisors(filteredArray)
  }

  // Table headers
  const headers = [
    { text: 'DATE CREATED', sorting: true },
    { text: 'NAME OF ADVISOR', sorting: true },
    { text: 'LANGUAGE', sorting: false },
    { text: 'COUNTRY', sorting: true },
    { text: 'CLIENTS', sorting: false },
    // { text: 'Impersonate Advisors', sorting: false },
    { text: 'API KEY', sorting: false },
  ]

  // Click events for table actions
  const onClickEvents = {
    setApiKey: handleOpenAPIKey,
    deleteAdvisor: handleOpenDeletationPopup,
    resendInvitation: resendEmail,
    openParentsPopup: handleOpenParentsPopup,
  }

  // Data for confirmation popup on advisor deletion
  const dataForDeletationPopup = {
    title: 'Are you sure want to remove advisor from the list?',
    buttons: {
      direction: 'vertical',
      content: [
        { id: 1, text: 'Continue', style: Styles.btns__delete, onClick: handleDeleteAdvisor },
        {
          id: 2,
          text: 'Cancel',
          style: Styles.btns__cancel,
          onClick: handleCloseDeletationPopup,
        },
      ],
    },
  }

  return (
    <div className={Styles.main_conatiner}>
      <div className={Styles.header}>
        <div className={Styles.header__first_block}>
          <h6>Advisors</h6>
          <div className={Styles.header__search}>
            <div className={Styles.header__search__container}>
              <SearchIcon />
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name or email" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
            </div>
          </div>
        </div>
        <div className={Styles.header__second_block}>
          <button className={Styles.header__btn} onClick={handleOpenAddAdvisorPopup}>
            <AddUserIcon />
            Add advisor
          </button>
        </div>
      </div>
      {advisors.length ? (
        <div className={Styles.data_container}>
          <Table headers={headers} data={advisors} userType={user?.user?.user_type} userDataType={'advisor'} onClickEvents={onClickEvents} />
        </div>
      ) : (
        <NoDataComponent text={'There are no advisors yet.'} />
      )}
      <CustomPagination dataArray={allAdvisors} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {isSetAPIKeyOpen && <SetApiModal advisorId={selectedAdvisor} open={isSetAPIKeyOpen} onClose={handleCloseAPIKey} />}
      {isOpenConfirmDeletationPopup && (
        <ConfirmationPopup open={isOpenConfirmDeletationPopup} onClose={handleCloseDeletationPopup} content={dataForDeletationPopup} width={'436px'} />
      )}
      {isOpenAddAdvisor && <AddAdvisor open={isOpenAddAdvisor} onClose={handleCloseAddAdvisorPopup} />}
      {isOpenParentsPopup && <ParentsPopup open={isOpenParentsPopup} onClose={handleCloseParentsPopup} parents={parents} userType={'Advisor'} user={selectedAdvisor} />}
    </div>
  )
}
