import { CircularProgress, IconButton, Modal, TextField } from '@mui/material'
import { Button, CenterRow, Flex, Text } from 'components/Core'
import { CloseIcon } from 'theme/assets/icons'
import { useFailConsultationMutation } from './service'
import { useEffect, useState } from 'react'
import { notify } from 'helpers'
import { useDispatch } from 'react-redux'
import { advisorOnbordingActions } from 'actions'

type Props = {
  open: boolean
  onClose: () => void
  data: {
    free_consultation_id: number | string
  }
}

export const FailFreeConsultation = ({ onClose, open, data }: Props) => {
  const [submit, { isLoading, isSuccess, isError, reset }] = useFailConsultationMutation()
  const [failReason, setFailReason] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (isSuccess) {
      notify('Successfully updated', false)
      onClose()
      const data = {
        token: localStorage.getItem('token'),
      }
      dispatch(advisorOnbordingActions.getAllApplies(data) as any)
    }
    if (isError) {
      notify('Failed to update', true)
    }
  }, [isSuccess, isError])
  const handleOnClose = () => {
    onClose()
    reset()
  }
  return (
    <Modal open={open} onClose={handleOnClose}>
      <Flex gap={20} maxWidth={['95%', '95%', '500px']} flexDirection="column" justifyContent="flex-start" variant="modal-container">
        <CenterRow>
          <Text variant="heading4">Reason</Text>
          <IconButton onClick={handleOnClose}>
            <CloseIcon />
          </IconButton>
        </CenterRow>
        <TextField value={failReason} onChange={(event) => setFailReason(event.target.value)} placeholder="Enter reason" multiline rows={4} />
        <Button
          onClick={() => {
            submit({ ...data, fail_reason: failReason })
          }}
          width={['100%']}
          alignSelf="center"
          variant="primary"
        >
          {isLoading ? <CircularProgress sx={{ color: 'white' }} size={25} /> : 'Submit'}
        </Button>
      </Flex>
    </Modal>
  )
}
