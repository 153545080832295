export const arabicFeedbackContent = {
  give: 'قدم ملاحظات',
  submitted: 'تم تقديم الملاحظات',
  good: 'جيد',
  bad: 'سيء',
  title: 'كيف كانت جلستك؟',
  submit: 'إرسال',
  success: 'شكرًا لملاحظاتك!',
  goBack: 'العودة إلى الصفحة الرئيسية',
  tellUsMore: 'أخبرنا المزيد',
  optional: 'اختياري',
  skipForNow: 'تخطي الآن',
  errors: {
    selectFeedback: 'يرجى أولاً اختيار الملاحظات!',
    alreadyGaveFeedback: 'لقد قدمت ملاحظات من قبل!',
    failedToCreateFeedback: 'فشل في إنشاء الملاحظات!',
    sessionNotFound: 'لم يتم العثور على الجلسة!',
    meetingIdIsRequired: 'معرف الاجتماع مطلوب!',
    unAuthorized: 'غير مصرح!',
  },
}
