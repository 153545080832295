//Core
import { useEffect } from 'react'
//Components
import {
  ParentFormRegistration,
  ParentOnboardingAddChild,
  ParentOnboardingFamilyDynamics,
  ParentOnboardingOptionalDisclaimer,
  ParentOnboardingParentalWellbeing,
  ParentOnboardingReport,
  ParentOnboardingSuccess,
} from '../../components/Auth'
// Enums
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME } from '../../data/enums'
// Utils
import { getLocalStorage, setLocalStorage } from '../../utils'
import { useQuestionnaireABTesting } from 'hooks/useQuestionnaireABTesting'
import { Mandatory } from 'components/Auth/ParentOnboarding/QuestionsPartMandatory/Mandatory'

export const ParentOnboardingPage = () => {
  // const [onboardingStep, setOnboardingStep] = useState(ONBOARDING_STEP.ADD_CHILD)

  useEffect(() => {
    const onboardingStateFromLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))

    if (onboardingStateFromLocalStorage) {
      setOnboardingStep(onboardingStateFromLocalStorage?.state)
    } else {
      const parentOnboardingObject = {
        state: ONBOARDING_STEP.ADD_CHILD,
        report_loading: true,
      }

      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(parentOnboardingObject))
    }
  }, [])

  const { abTestingState, isVariantA, isVariantB, getSteps, navigateToParentForm, setOnboardingStep, setAddChildStep } = useQuestionnaireABTesting()

  useEffect(() => {
    if (!abTestingState.onboardingStep) setOnboardingStep(ONBOARDING_STEP.ADD_CHILD)
  }, [!abTestingState.onboardingStep])
  return (
    <>
      {abTestingState.onboardingStep === ONBOARDING_STEP.ADD_CHILD && (
        <ParentOnboardingAddChild
          navigateToParentForm={navigateToParentForm}
          isVariantA={isVariantA}
          isVariantB={isVariantB}
          setAddChildStep={setAddChildStep}
          addChildStep={abTestingState.addChildStep}
          setOnboardingStep={setOnboardingStep}
        />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.QUESTIONNAIRE && (
        <Mandatory setAddChildStep={setAddChildStep} isVariantB={isVariantB} getSteps={getSteps} isVariantA={isVariantA} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER && isVariantB && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.FAMILY_DYNAMICS} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS && isVariantB && <ParentOnboardingFamilyDynamics setOnboardingStep={setOnboardingStep} />}
      {abTestingState.onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER && isVariantB && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.PARENTAL_WELLBEING} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING && isVariantB && <ParentOnboardingParentalWellbeing setOnboardingStep={setOnboardingStep} />}
      {abTestingState.onboardingStep === ONBOARDING_STEP.REPORT && (
        <ParentOnboardingReport setAddChildStep={setAddChildStep} isVariantA={isVariantA} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.FORM_REGISTRATION && (
        <ParentFormRegistration isVariantA={isVariantA} setAddChildStep={setAddChildStep} setOnboardingStep={setOnboardingStep} />
      )}
      {abTestingState.onboardingStep === ONBOARDING_STEP.SUCCESS && <ParentOnboardingSuccess />}
    </>
  )
}
