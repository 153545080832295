/* eslint-disable no-unused-vars */
//Core
import { func, number, string } from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
// Instruments
import InfiniteScroll from 'react-infinite-scroll-component'
// Styles
import Styles from './styles.module.scss'
// Components
import { FileAttachmentLoader } from './FileAttachmentLoader'
import { NewMessageBar } from './NewMessageBar'
import { Placeholder } from './Placeholder'
import { RecievedMessageInstance } from './RecievedMessageInstance'
import { SentMessageInstance } from './SentMessageInstance'
// Tools
import WebSocketService from '../../../../websocket'
//Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as UserIcon } from '../../../../theme/assets/icons/user_updated.svg'
//Heplers
import { notify } from '../../../../helpers'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

export const MessagesHistory = ({
  currentUserId,
  currentUserEmail,
  roomId,
  chatId,
  onNewMessageSend,
  onViewLastMessages,
  onChatDetailsOpenning,
  currentChat,
  selectedChat,
  goBackToChatList,
}) => {
  const user = useSelector((state) => state.auth.user)
  // Scroll to the bottom of the messages container
  const scrollableContainerRef = useRef(null)
  const scrollToBottom = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight
    }
  }
  // WebSocket integration
  const [messageToAdd, setMessageToAdd] = useState({})
  const [messagesHistory, setMessagesHistory] = useState([])
  const [maxPages, setMaxPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [fetching, setFetching] = useState(false)
  const [fetchedRoomId, setFetchedRoomId] = useState(undefined)
  const [webSocketInstance, setWebSocketInstance] = useState(null)
  const [groupedMessages, setGroupedMessages] = useState([])

  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false)
  const [isMessageSending, setIsMessageSending] = useState(false)
  const [futureMessageId, setFutureMessageId] = useState(undefined)
  const [fileToApload, setFileToApload] = useState({})

  const { t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'chat_history', en)
    i18n.addResourceBundle('ar', 'chat_history', ar)
  }, [])

  useEffect(() => {
    setWebSocketInstance(new WebSocketService('chat', roomId, user))
  }, [roomId, user])

  function modifyPages(pages, newMsg) {
    let toAdd = newMsg

    const newPages = pages.map((p) => {
      if (toAdd !== undefined) {
        const res = [toAdd, ...p]
        toAdd = res.length > 20 ? res.pop() : undefined
        return res
      } else {
        return p
      }
    })

    return toAdd !== undefined ? [...newPages, [toAdd]] : newPages
  }

  const setWebSocketCallbacks = (webSocketInstance) => {
    webSocketInstance.setCallbacks(
      (listOfMessages) => {
        setMessagesHistory(messagesHistory.length < currentPage ? [...messagesHistory, listOfMessages.messages] : messagesHistory)
        setMaxPages(listOfMessages.max_pages)
      },
      (newMessage) => {
        // console.log(newMessage, 'newMessage')
        setMessageToAdd(newMessage)
      },
      (shareFile) => {
        // console.log('IAMHERE')
        // console.log(shareFile)
      },
    )

    webSocketInstance.setErrorCallback((error) => {
      notify(error, true)
      setIsAttachmentLoading(false)
      setIsMessageSending(false)
      setMessageToAdd({})
    })
  }

  const fetchData = (page) => {
    if (webSocketInstance && webSocketInstance.state !== 1) {
      webSocketInstance.connect()
      const waitForSocketConnection = (callback) => {
        setTimeout(() => {
          if (webSocketInstance.state() === 1) {
            callback()
          } else {
            waitForSocketConnection(callback)
          }
        }, 100)
      }

      waitForSocketConnection(() => {
        setWebSocketCallbacks(webSocketInstance)
        webSocketInstance.fetchMessages(currentUserId, roomId, page)
      })
    } else if (webSocketInstance) {
      setWebSocketCallbacks(webSocketInstance)
      webSocketInstance.fetchMessages(currentUserId, roomId, page)
    }
  }

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    if (messagesHistory.length < currentPage) {
      fetchData(currentPage)
      setFetchedRoomId(roomId)
      setFetching(true)
    } else if (fetchedRoomId !== roomId || roomId === undefined) {
      setMessagesHistory([])
      setFetchedRoomId(undefined)
      setCurrentPage(1)
    }
  }, [fetchedRoomId, roomId, chatId, currentPage, messagesHistory, fetching])

  useEffect(() => {
    onViewLastMessages()
    onNewMessageSend()
  }, [messagesHistory, messageToAdd])

  function fileToByteArray(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const arrayBuffer = event.target.result
        const byteArray = new Uint8Array(arrayBuffer)
        resolve(byteArray)
      }
      reader.onerror = (error) => {
        // console.log(error)
        reject(error)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const handleNewMessageSend = async (data, files) => {
    setIsMessageSending(true)
    const messageObject = {
      from: currentUserId,
      roomId: roomId,
    }

    try {
      if (data) {
        messageObject['content'] = data
        await webSocketInstance.newChatMessage(messageObject)
      }

      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          setFutureMessageId(messagesHistory[0][0]?.id + 1)
          setIsAttachmentLoading(true)
          setFileToApload(file)
          const byteArray = await fileToByteArray(file)

          const fileObject = {
            filename: file.name,
            size: file.size,
            data: Array.from(byteArray),
          }

          // console.log(fileObject)

          messageObject['content'] = fileObject
          await webSocketInstance.shareFile(messageObject)
        }
      }
    } catch (e) {
      // console.log(e)
    }

    setTimeout(() => {
      scrollToBottom()
    }, 50)
  }

  useEffect(() => {
    setGroupedMessages([])
    const allMessages = messagesHistory.flatMap((page) => page)
    if (allMessages.length !== 0) {
      const messagesByDay = allMessages.reduce((groupedMessages, msg) => {
        const messageDate = new Date(msg.timestamp)
        const dateLabel = messageDate.toLocaleDateString(undefined, { day: 'numeric', month: 'long' })

        if (!groupedMessages[dateLabel]) {
          groupedMessages[dateLabel] = []
        }

        groupedMessages[dateLabel].push(msg)

        return groupedMessages
      }, {})

      const groupedMessagesArray = Object.keys(messagesByDay).map((date) => {
        return {
          date: date,
          messages: messagesByDay[date].reverse(),
        }
      })

      setGroupedMessages(groupedMessagesArray)
    }
  }, [messagesHistory, currentPage])

  useEffect(() => {
    if (messagesHistory.length !== 0) {
      const includes = messagesHistory[0].find((message) => message.id === messageToAdd.id)
      if (includes === undefined && fetchedRoomId === messageToAdd.room_id) {
        setMessagesHistory((prev) => {
          const newArray = [...prev]
          newArray[0] = [messageToAdd, ...newArray[0]]
          return newArray
        })
      }
      setIsAttachmentLoading(false)
      setIsMessageSending(false)
    }
  }, [messageToAdd])

  const dataLength = messagesHistory.reduce((sum, page) => sum + page?.length, 0)

  const returnChatName = () => {
    if (user?.user?.user_type === 'parent') {
      return <p>{selectedChat?.user_name}</p>
    } else {
      if (selectedChat?.user_role === 'parent') {
        return (
          <div className={Styles.header_content}>
            <p>Client: {selectedChat?.user_name}</p>
            <p>Profile: {selectedChat?.profile_name || selectedChat?.user_name}</p>
          </div>
        )
      } else {
        return <p>{selectedChat?.user_name}</p>
      }
    }
  }

  const returnProfilePic = () => {
    if (user?.user?.user_type === 'parent') {
      return selectedChat?.user_profile_pic
    } else {
      if (selectedChat?.user_role === 'parent') {
        return selectedChat?.profile_pic
      } else {
        return selectedChat?.user_profile_pic
      }
    }
  }

  return (
    <div className={Styles.messagesHistory}>
      <div className={Styles.chat_header}>
        <ArrowLeftIcon className={Styles.listVisibility} style={{ cursor: 'pointer' }} onClick={goBackToChatList} />
        <div className={Styles.chat_header__photo}>{selectedChat?.profile_pic || selectedChat?.user_profile_pic ? <img src={returnProfilePic()} /> : <UserIcon />}</div>
        {returnChatName()}
      </div>
      <div className={Styles.messagesHistory__messagesBlock} ref={scrollableContainerRef} id="scrollableDiv">
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchMoreData}
          style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={maxPages > currentPage}
          scrollableTarget="scrollableDiv"
          x
        >
          {dataLength !== 0 || groupedMessages.length !== 0 ? (
            groupedMessages.map((group, groupIndex) => {
              return (
                <div className={Styles.messagesHistory__messagesBlock__dayContainer} key={groupIndex}>
                  <div className={Styles.messagesHistory__messagesBlock__dayContainer__date}>
                    <p>{group.date}</p>
                  </div>
                  {group.messages.map((message) => {
                    return (
                      <div key={message.id} className={Styles.messagesHistory__messagesBlock__dayContainer__messagesContainer}>
                        {message.author === currentUserEmail ? (
                          <SentMessageInstance messageInfo={{ content: message.content, timestamp: message.timestamp, attachment: message.attachment }} key={message.id} />
                        ) : (
                          <RecievedMessageInstance
                            messageInfo={{
                              content: message.content,
                              timestamp: message.timestamp,
                              attachment: message.attachment,
                            }}
                            profileAvatar={currentChat.profile_pic}
                            onChatDetailsOpenning={onChatDetailsOpenning}
                          />
                        )}
                        {message?.id + 1 === futureMessageId && isAttachmentLoading && <FileAttachmentLoader file={fileToApload} />}
                      </div>
                    )
                  })}
                </div>
              )
            })
          ) : (
            <>{isMessageSending && isAttachmentLoading ? <FileAttachmentLoader file={fileToApload} /> : <Placeholder text={t('chat_history:no_messages')} />}</>
          )}
        </InfiniteScroll>
      </div>

      <div className={Styles.messagesHistory__sendBtnBlock}>
        <NewMessageBar isMessageSending={isMessageSending || isAttachmentLoading} onSubmit={handleNewMessageSend} />
      </div>
    </div>
  )
}

MessagesHistory.propTypes = {
  currentUserId: number,
  currentUserEmail: string,
  roomId: string,
  chatId: number,
  onNewMessageSend: func,
  // onShowContactDetails: func,
  onViewLastMessages: func,
}
