// Core
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Instruments
import { useForm, useWatch } from 'react-hook-form'
// Actions
import { setTherapistFullData, setDocumentsForRegistration } from '../../../reducers/authSlice'
import { authActions, settingsActions } from '../../../actions'
// Validation
import { schemaValid } from './validLogic'
//Dropzone
import { useDropzone } from 'react-dropzone'
//MUI
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import styled from '@emotion/styled'
// Data
import {
  countries,
  languages,
  provide_clinical,
  expertise,
  education_level,
  professional_qualities,
  licence,
  services_selector,
  j_advantage,
  hintForPersonalDocuments,
  hintForEducationalDocuments,
  hintForProfessionalDocuments,
} from '../../../data'
//Utils
import { returnLanguagesArray, returnEducationalLevel, returnLanguagesFromArray, convertBytes, returnCurrentyActivity, getLocalStorage } from '../../../utils'
import dayjs from 'dayjs'
import cuid from 'cuid'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextField, TextFieldAutocomplete, TextFieldMultiple, DatePickerField, PhoneNumberInput, TextAreaField } from '../../GeneralComponents'
import { SummaryTherapistRegistration } from '../SummaryTherapistRegistration'
//Icons
import { ReactComponent as ArrowDownIcon } from '../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as ArrowLeftIcon } from '../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as AddIcon } from '../../../theme/assets/icons/add.svg'
import { ReactComponent as UploadIcon } from '../../../theme/assets/icons/upload_file_icon.svg'
import { ReactComponent as ClipIcon } from '../../../theme/assets/icons/paper_clip_blue_icon.svg'
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_updated.svg'
import { debounce } from '@mui/material'
import { defaultValues } from './hookFormConstants'
import { notify } from '../../../helpers'
import { useNavigate } from 'react-router-dom'
import { useSignUpForm } from './hooks/useSignUpForm'
import { Spinner } from '../../ChatSystem/Common/Spinner'

//Styles
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderColor: '#D89B95',
  borderStyle: 'dashed',
  borderRadius: 12,
  outline: 'none',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#D89B95',
}

export const SignUp = () => {
  const history = useNavigate()
  const { createTherapist, isLoading } = useSignUpForm()
  const dispatch = useDispatch()

  const therapistInfo = useSelector((state) => state.auth.therapistFullData)
  const documents = useSelector((state) => state.auth.documentsForRegistartion)
  const error = useSelector((state) => state.general.error)

  const [blockNumber, setBlockNumber] = useState(1)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [groupedFiles, setGroupedFiles] = useState(undefined)
  const [documentsType, setDocumentsType] = useState('personal')

  const steps = ['Personal', 'Educational', 'Professional']
  const [activeStep, setActiveStep] = useState(0)

  const [valuesForLanguages, setValuesForLanguages] = useState([])
  const [valuesForLisence, setValuesForLisence] = useState([])
  const [valueForQualification, setValueForQualification] = useState([])
  const [valuesForExpertise, setValuesForExpertise] = useState([])
  const [valuesForServices, setValuesForServices] = useState([])

  const [valueForAdvantage, setValueForAdvantage] = useState({})

  const [openCustomQualificationValue, setOpenCustomQualificationValue] = useState(false)
  const [openCustomLicenceValue, setOpenCustomLicenceValue] = useState(false)
  const [openCustomExpertiseValue, setOpenCustomExpertiseValue] = useState(false)
  const [openCustomServicesValue, setOpenCustomServicesValue] = useState(false)
  const [openCustomAdvantage, setOpenCustomAdvantage] = useState(false)
  //Styles
  const CustomStep = styled(Step)`
    & .MuiSvgIcon-root {
      color: #c7bcce;
    }
    ,
    & .MuiStepIcon-root.Mui-active {
      color: #8450a0 !important;
      font-family: 'Poppins_Regular';
      font-weight: 600;
    }
    ,
    & .MuiStepLabel-label.Mui-disabled {
      font-family: 'Poppins_Regular';
    }
    ,
    & .MuiStepLabel-label.Mui-active {
      font-family: 'Poppins_Regular';
      font-weight: 600;
      color: #8450a0 !important;
    }
    ,
    & .MuiStepIcon-root.Mui-completed {
      color: #c7bcce !important;
    }
  `

  const today = dayjs()
  const maximumDate = today.add(-18, 'year')
  const minimumDate = today.add(-90, 'year')

  const { acceptedFiles, getRootProps, getInputProps, isFocused } = useDropzone({
    accept: { 'text/plain': ['.pdf', '.doc', '.docx'] },
    maxFiles: 5,
    maxSize: 10 * 1024 * 1024,
  })

  const {
    control,
    formState: { errors, isValid },
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
  } = useForm({
    mode: 'all',
    resolver: schemaValid(blockNumber),
    defaultValues,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
    }),
    [isFocused],
  )

  const watchEmailValue = useWatch({
    name: 'email',
    control,
  })
  const watchBioValue = useWatch({
    name: 'bio',
    control,
  })

  useEffect(() => {
    acceptedFiles.map((file) => setUploadedFiles((prev) => [...prev, { id: cuid(), type: documentsType, file }]))
  }, [acceptedFiles])

  const debouncedCheckEmail = useCallback(
    debounce((email) => {
      dispatch(authActions.checkUserEmail({ email }))
    }, 1000),
    [],
  )

  useEffect(() => {
    const emailValue = getValues('email')
    if (emailValue) {
      debouncedCheckEmail(emailValue)
    }
  }, [watchEmailValue, debouncedCheckEmail])

  useEffect(() => {
    if (documents) {
      const filteredDocuments = documents?.filter((document) => document.type === documentsType)
      setGroupedFiles([...filteredDocuments])
    }

    if (therapistInfo?.phone_number) {
      setValue('phone_number', therapistInfo.phone_number)
    }

    const newArrayOfValuesForLaguages = []
    const newArrayOfValuesForLisence = []
    const newArrayOfValuesForExpertise = []
    const newArrayOfValuesForServices = []
    const newArrayOfValuesForQualifications = []

    if (therapistInfo?.languages) {
      for (const item of therapistInfo?.languages) {
        const valueObject = { value: returnLanguagesFromArray(item) }
        newArrayOfValuesForLaguages.push(valueObject)
      }
    }

    if (therapistInfo?.licence) {
      for (const item of therapistInfo?.licence) {
        const valueObject = { value: item }
        newArrayOfValuesForLisence.push(valueObject)
      }
    }

    if (therapistInfo?.professional_qualities) {
      for (const item of therapistInfo?.professional_qualities) {
        const valueObject = { value: item }
        newArrayOfValuesForQualifications.push(valueObject)
      }
    }

    if (therapistInfo?.expertise) {
      for (const item of therapistInfo?.expertise) {
        const valueObject = { value: item }
        newArrayOfValuesForExpertise.push(valueObject)
      }
    }

    if (therapistInfo?.clinical_services_provided) {
      for (const item of therapistInfo?.clinical_services_provided) {
        const valueObject = { value: item }
        newArrayOfValuesForServices.push(valueObject)
      }
    }

    setValuesForLanguages([...newArrayOfValuesForLaguages])
    setValuesForLisence([...newArrayOfValuesForLisence])
    setValuesForExpertise([...newArrayOfValuesForExpertise])
    setValuesForServices([...newArrayOfValuesForServices])
    setValueForQualification([...newArrayOfValuesForQualifications])
  }, [therapistInfo, documentsType])

  const formatDate = (inputDateStr) => {
    const inputDate = new Date(inputDateStr)

    const day = inputDate.getDate()
    const month = inputDate.getMonth() + 1
    const year = inputDate.getFullYear()

    const formattedDate = `${day}/${month}/${year}`

    return formattedDate
  }

  const returnCountryCode = (inputStr) => {
    const underscoreIndex = inputStr.indexOf('_')

    if (underscoreIndex !== -1) {
      return inputStr.substring(0, underscoreIndex)
    }

    return inputStr
  }

  useEffect(() => {
    if (error) {
      setError('email', { type: 'custom', message: error })
    }
  }, [error])

  const handleSetDataForRegistration = async (data) => {
    if (blockNumber === 7) {
      const response = await createTherapist({ therapistInfo, documents: [...documents, ...uploadedFiles] })
      if (response.error) {
        notify(response?.error?.message)
      } else {
        /**
         * Navigates to the 'therapist/success' route if the response from the `createTherapist` function does not contain an error.
         *
         */
        history('/signup/therapist/success')
      }
      return
    }
    if (blockNumber === 1) {
      dispatch(
        setTherapistFullData({
          ...therapistInfo,
          full_name: data.full_name,
          birthday: formatDate(data.birthday),
          country: returnCountryCode(data.country.value),
          languages: data.languages?.map((language) => returnLanguagesArray(language.value))?.filter((i) => i !== null),
          phone_number: data.phone_number,
          email: data.email,
          bio: data.bio,
        }),
      )
    } else if (blockNumber === 2) {
      dispatch(
        setTherapistFullData({
          ...therapistInfo,
          professional_qualities: data.professional_qualities?.map((option) => option.value),
          experience: data.experience,
          education_level: returnEducationalLevel(data.education_level.value),
          licence: data.licence?.map((option) => option.value),
        }),
      )
    } else if (blockNumber === 3) {
      dispatch(
        setTherapistFullData({
          ...therapistInfo,
          expertise: data.expertise?.map((option) => option.value),
          clinical_services_provided: data.clinical_services_provided?.map((option) => option.value),
          current_status: returnCurrentyActivity(data.current_status.value),
          job_advantages: data.job_advantages.value || data.job_advantages,
          age_groups_served: data.age_groups_served,
        }),
      )
      setDocumentsType('personal')
    }

    if (uploadedFiles.length) {
      dispatch(setDocumentsForRegistration([...documents, ...uploadedFiles]))
    }

    if (blockNumber >= 4) {
      switch (documentsType) {
        case 'personal':
          setDocumentsType('educational')
          setActiveStep(1)
          break
        case 'educational':
          setDocumentsType('professional')
          setActiveStep(2)
          break
      }
    }

    if (blockNumber === 6) {
      setDocumentsType('')
    }

    setUploadedFiles([])
    setBlockNumber((prev) => prev + 1)
  }

  const handleGoBack = () => {
    if (blockNumber === 1) {
      return
    }

    if (blockNumber === 7) {
      setActiveStep(2)
      setDocumentsType('professional')
    }

    if (blockNumber >= 4 && blockNumber !== 7) {
      switch (documentsType) {
        case 'educational':
          setDocumentsType('personal')
          setActiveStep(0)
          break
        case 'professional':
          setDocumentsType('educational')
          setActiveStep(1)
          break
      }
    }

    setUploadedFiles([])
    setBlockNumber((prev) => prev - 1)
  }

  const handleAddCustomField = (field, parentField) => {
    const value = getValues(field)
    const previousValues = getValues(parentField)
    if (!value) {
      setError(`${field}`, { type: 'custom', message: `You can't add an empty option.` })
    } else {
      const regex = /^(?!^[/\.])[a-zA-Z\s/.()]*$/
      const isMatch = regex.test(value)
      if (isMatch) {
        switch (field) {
          case 'custom_services':
            setValuesForServices((prev) => [...prev, { value }])
            setValue(parentField, [...previousValues, { value }], { shouldValidate: true })
            setOpenCustomServicesValue(false)
            break
          case 'custom_expertise':
            setValuesForExpertise((prev) => [...prev, { value }])
            setValue(parentField, [...previousValues, { value }], { shouldValidate: true })
            setOpenCustomExpertiseValue(false)
            break
          case 'custom_qualification':
            setValueForQualification((prev) => [...prev, { value }])
            setValue(parentField, [...previousValues, { value }], { shouldValidate: true })
            setOpenCustomQualificationValue(false)
            break
          case 'custom_advant':
            setValueForAdvantage({ value })
            setValue(parentField, { value }, { shouldValidate: true })
            setOpenCustomAdvantage(false)
            break
          case 'custom_licence':
            setValuesForLisence((prev) => [...prev, { value }])
            setValue(parentField, [...previousValues, { value }], { shouldValidate: true })
            setOpenCustomLicenceValue(false)
            break
        }
      } else {
        setError(`${field}`, { type: 'custom', message: `You can't provide option in this way.` })
      }
    }
  }

  const removeFileFromArray = (id) => {
    let newArray

    if (uploadedFiles?.length) {
      newArray = uploadedFiles?.filter((file) => file.id !== id)
      setUploadedFiles(newArray)
    } else {
      newArray = groupedFiles?.filter((file) => file.id !== id)
      const documentsForRegistartionNew = documents?.filter((file) => file.id !== id)
      dispatch(setDocumentsForRegistration([...documentsForRegistartionNew]))
      setGroupedFiles(newArray)
    }
  }

  const stepController = useMemo(() => {
    if (blockNumber < 4) {
      return (
        <div onClick={handleSubmit(handleSetDataForRegistration)} className={isValid ? Styles.purpleBtn : Styles.purpleBtn_disabled}>
          Continue
        </div>
      )
    }
    if (blockNumber !== 7) {
      return (
        <div onClick={handleSubmit(handleSetDataForRegistration)} className={uploadedFiles?.length || groupedFiles?.length ? Styles.purpleBtn : Styles.purpleBtn_disabled}>
          Continue
        </div>
      )
    } else {
      return (
        <div onClick={handleSubmit(handleSetDataForRegistration)} style={{ gap: '10px' }} className={isLoading ? Styles.purpleBtn_disabled : Styles.purpleBtn}>
          <p>Send for approval</p> {isLoading && <Spinner width="22px" />}
        </div>
      )
    }
  }, [blockNumber, isValid, uploadedFiles?.length, groupedFiles?.length, isValid, isLoading])

  return (
    <div className={Styles.main_container}>
      <h4 className={Styles.headline}>{blockNumber === 7 ? 'Summary' : 'Sign up'}</h4>
      {blockNumber === 1 && (
        <div className={Styles.block}>
          <h6>Personal info</h6>
          <TextField label={'Email address'} register={register('email')} placeholder={'youremail@gmail.com'} error={errors?.email} error_wraper={Styles.error_wraper} />
          <TextField label={'Full name'} register={register('full_name')} placeholder={'Name Surname'} error={errors.full_name} error_wraper={Styles.error_wraper} />
          <DatePickerField error={errors.birthday} name={'birthday'} control={control} label={'Birthday'} maximumDate={maximumDate} minimumDate={minimumDate} />
          <PhoneNumberInput
            label={'Phone number'}
            value={therapistInfo?.phone_number}
            placeholder={'Enter your phone number'}
            register={register('phone_number')}
            setValue={setValue}
            setError={setError}
            hintMessage={'Type your mobile number with country code'}
            error={errors.phone_number}
            error_wraper={Styles.error_wraper}
          />
          <div className={Styles.two_fields_container__input}>
            <TextFieldAutocomplete
              name="country"
              control={control}
              options={countries}
              setValue={setValue}
              disabled={false}
              class_wrapper={Styles.form_item}
              class_field={Styles.form_field_country}
              error={errors?.country}
              label={'Country'}
              placeholder={'Select your country'}
            />
            <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
          </div>
          <div className={Styles.two_fields_container__input}>
            <TextFieldMultiple
              name="languages"
              value={valuesForLanguages}
              setNewValue={setValuesForLanguages}
              control={control}
              options={languages}
              setValue={setValue}
              freeSolo={true}
              disabled={false}
              error={errors?.languages}
              class_wrapper={Styles.form_item}
              hintMessage={'You can select multiple languages'}
              label={'Language'}
              placeholder="Select your language"
              disableNewValue
            />
            <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
          </div>
          <TextAreaField
            register={register('bio')}
            placeholder="Write something about you..."
            customOnChange={(value) => {
              setValue('bio', value)
            }}
            name="bio"
            label="About Me"
            control={control}
            error={errors.bio}
            maxlength={510}
            value={watchBioValue}
          />
        </div>
      )}
      {blockNumber === 2 && (
        <div className={Styles.block}>
          <h6>Professional info</h6>
          <div className={Styles.with_custom}>
            <div className={Styles.two_fields_container__input}>
              <TextFieldMultiple
                name="professional_qualities"
                value={valueForQualification}
                setNewValue={setValueForQualification}
                openCustomField={setOpenCustomQualificationValue}
                control={control}
                options={professional_qualities}
                setValue={setValue}
                freeSolo={true}
                disabled={false}
                error={errors?.professional_qualities}
                class_wrapper={Styles.form_item}
                hintMessage={'Multiple professional qualifications can be specified'}
                label={'Professional qualifications'}
                placeholder="Select your professional qualifications"
              />
              <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
            </div>
            {openCustomQualificationValue && (
              <div className={Styles.two_fields_container__input}>
                <TextField
                  label={'Add custom option'}
                  register={register('custom_qualification')}
                  error={errors.custom_qualification}
                  class_wrapper={Styles.two_fields_container__multiselector}
                />
                <AddIcon onClick={() => handleAddCustomField('custom_qualification', 'professional_qualities')} className={Styles.two_fields_container__input__add_icon} />
              </div>
            )}
          </div>
          <TextField label={'Years of experience'} register={register('experience')} placeholder={'10+'} error={errors.experience} error_wraper={Styles.error_wraper} />
          <div className={Styles.two_fields_container__input}>
            <TextFieldAutocomplete
              name="education_level"
              control={control}
              options={education_level}
              setValue={setValue}
              disabled={false}
              class_wrapper={Styles.form_item}
              error={errors?.education_level}
              label={'Educational degree'}
              placeholder={'Select your educational degree'}
            />
            <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
          </div>
          <div className={Styles.with_custom}>
            <div className={Styles.two_fields_container__input}>
              <TextFieldMultiple
                name="licence"
                value={valuesForLisence}
                setNewValue={setValuesForLisence}
                openCustomField={setOpenCustomLicenceValue}
                control={control}
                options={licence}
                setValue={setValue}
                freeSolo={true}
                disabled={false}
                error={errors?.licence}
                class_wrapper={Styles.form_item}
                hintMessage={'Multiple license boards can be specified'}
                label={'Licensing board you belong to'}
                placeholder="Select your license boards"
              />
              <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
            </div>
            {openCustomLicenceValue && (
              <div className={Styles.two_fields_container__input}>
                <TextField
                  label={'Add custom option'}
                  register={register('custom_licence')}
                  error={errors.custom_licence}
                  class_wrapper={Styles.two_fields_container__multiselector}
                />
                <AddIcon onClick={() => handleAddCustomField('custom_licence', 'licence')} className={Styles.two_fields_container__input__add_icon} />
              </div>
            )}
          </div>
        </div>
      )}
      {blockNumber === 3 && (
        <div className={Styles.block}>
          <h6>Professional info</h6>
          <div className={Styles.with_custom}>
            <div className={Styles.two_fields_container__input}>
              <TextFieldMultiple
                name="expertise"
                value={valuesForExpertise}
                setNewValue={setValuesForExpertise}
                openCustomField={setOpenCustomExpertiseValue}
                control={control}
                options={expertise}
                setValue={setValue}
                freeSolo={true}
                disabled={false}
                error={errors?.expertise}
                class_wrapper={Styles.form_item}
                hintMessage={'Multiple areas of experise can be specified'}
                label={'Areas of expertise'}
                placeholder="Select your areas of expertise"
              />
              <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
            </div>
            {openCustomExpertiseValue && (
              <div className={Styles.two_fields_container__input}>
                <TextField
                  label={'Add custom option'}
                  register={register('custom_expertise')}
                  error={errors.custom_expertise}
                  class_wrapper={Styles.two_fields_container__multiselector}
                />
                <AddIcon onClick={() => handleAddCustomField('custom_expertise', 'expertise')} className={Styles.two_fields_container__input__add_icon} />
              </div>
            )}
          </div>
          <div>
            <div className={Styles.two_fields_container__input}>
              <TextFieldMultiple
                name="clinical_services_provided"
                value={valuesForServices}
                setNewValue={setValuesForServices}
                openCustomField={setOpenCustomServicesValue}
                control={control}
                options={provide_clinical}
                setValue={setValue}
                freeSolo={true}
                disabled={false}
                error={errors?.clinical_services_provided}
                class_wrapper={Styles.form_item}
                hintMessage={'Multiple clinical services can be specified'}
                label={'In which facility do you currently provide clinical services?'}
                placeholder="Select your clinical services"
              />
              <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
            </div>
            {openCustomServicesValue && (
              <div className={Styles.two_fields_container__input}>
                <TextField
                  label={'Add custom option'}
                  register={register('custom_services')}
                  error={errors.custom_services}
                  class_wrapper={Styles.two_fields_container__multiselector}
                />
                <AddIcon onClick={() => handleAddCustomField('custom_services', 'clinical_services_provided')} className={Styles.two_fields_container__input__add_icon} />
              </div>
            )}
          </div>
          <div className={Styles.two_fields_container__input}>
            <TextFieldAutocomplete
              name="current_status"
              control={control}
              options={services_selector}
              setValue={setValue}
              disabled={false}
              class_wrapper={Styles.form_item}
              error={errors?.current_status}
              label={'Do you currently actively provide clinical services?'}
              placeholder={'Select your currently activity'}
            />
            <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
          </div>
          <TextField
            label={'Which age groups do you currently work with'}
            register={register('age_groups_served')}
            placeholder={`Enter age groups`}
            error={errors.age_groups_served}
            error_wraper={Styles.error_wraper}
          />
          <div className={Styles.with_custom}>
            <div className={Styles.two_fields_container__input}>
              <TextFieldAutocomplete
                name="job_advantages"
                defaultValue={valueForAdvantage}
                manualSetValue={setValueForAdvantage}
                openCustomField={setOpenCustomAdvantage}
                control={control}
                options={j_advantage}
                setValue={setValue}
                freeSolo={true}
                disablePortal={true}
                disabled={false}
                class_wrapper={Styles.form_item}
                class_field={Styles.form_field_country}
                error={errors?.job_advantages}
                label={'What would the main advantages of joining Numuw for you?'}
                placeholder={'Select your main advantages'}
              />
              <ArrowDownIcon style={{ width: '32px', height: '32px' }} className={Styles.two_fields_container__input__icon} />
            </div>
            {openCustomAdvantage && (
              <div className={Styles.two_fields_container__input}>
                <TextField label={'Add custom option'} register={register('custom_advant')} error={errors.custom_advant} />
                <AddIcon onClick={() => handleAddCustomField('custom_advant', 'job_advantages')} className={Styles.two_fields_container__input__add_icon} />
              </div>
            )}
          </div>
        </div>
      )}
      {blockNumber >= 4 && blockNumber !== 7 && (
        <div className={Styles.block}>
          <div className={Styles.block__header}>
            <h6>Upload your {documentsType} documents</h6>
            <p>You can attach maximum 5 files</p>
          </div>
          <div className={Styles.block__dropzone}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <div className={Styles.inner_container}>
                <UploadIcon />
                <div className={Styles.text_container}>
                  <p className={Styles.paragraph}> Drag .pdf,.doc,.docx file here or click to open browser</p>
                  <p className={Styles.description}>Maximum upload size: 10MB</p>
                </div>
              </div>
            </div>
            <ul>
              {documentsType === 'personal' &&
                hintForPersonalDocuments?.map((hint) => {
                  return <li key={hint.id}>{hint.value}</li>
                })}
              {documentsType === 'educational' &&
                hintForEducationalDocuments?.map((hint) => {
                  return <li key={hint.id}>{hint.value}</li>
                })}
              {documentsType === 'professional' &&
                hintForProfessionalDocuments?.map((hint) => {
                  return <li key={hint.id}>{hint.value}</li>
                })}
            </ul>
          </div>
          {(uploadedFiles.length ? uploadedFiles : groupedFiles)?.map((file) => {
            return (
              <div key={file?.id} className={Styles.file_container}>
                <ClipIcon />
                <p>{file?.file?.name}</p>
                <span>({convertBytes(file?.file?.size)})</span>
                <div onClick={() => removeFileFromArray(file?.id)} className={Styles.file_container__delete}>
                  <CloseIcon />
                </div>
              </div>
            )
          })}
          <Stepper activeStep={activeStep} className={Styles.stepper}>
            {steps.map((label, index) => {
              return (
                <CustomStep key={label}>
                  <StepLabel>{label}</StepLabel>
                </CustomStep>
              )
            })}
          </Stepper>
        </div>
      )}
      {blockNumber === 7 && (
        <div className={Styles.block}>
          <SummaryTherapistRegistration setBlockNumber={setBlockNumber} setDocumentsType={setDocumentsType} setActiveStep={setActiveStep} />
        </div>
      )}
      <div className={Styles.footer}>
        <div onClick={handleGoBack} className={blockNumber === 1 ? Styles.noBorderBtn_disabled : Styles.noBorderBtn}>
          <ArrowLeftIcon />
          Back
        </div>
        {stepController}
      </div>
    </div>
  )
}
