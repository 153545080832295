import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type QueryArgs = {
  payload: {
    child_info: Object
    questionnaire: Object
    family_dynamics: Object
    parental_wellbeing: Object
  }
  token: string | undefined
}

export const { useCreateNewProfileMutation } = rootServiceApi.injectEndpoints({
  endpoints(build) {
    return {
      createNewProfile: build.mutation<{ id: number }, QueryArgs>({
        query: (data) => ({
          url: apiRoutes.parent.addChild,
          method: 'POST',
          body: data.payload,
          headers: {
            Authorization: `Token ${data.token}`,
          },
        }),
      }),
    }
  },
})
