import type { Options } from "react-lottie";

export const defaultOptions = (options: Options) => ({
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  ...options,
});
