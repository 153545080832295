export const englishValidationErrors = {
  required: 'This field is required',
  lengthShouldBeBetween3To30: 'Your name should be at least 3-30 characters long',
  nameShouldNotContainNumber: 'Your name should not contain numbers',
  invalidDate: 'Invalid date!',
  mustBeOneYearAgo: 'Date must be at least 1 year ago',
  mustBeYounger: 'Child must be younger',
  atLeastThreeCharLong: 'This field should be at least 3 characters long',
  noMoreThan30CharLong: 'This field should be no more than 30 characters long',
  noMoreThan510CharLong: 'This field should be no more than 510 characters long',
  noMOreThan5CharLong: 'This field should be no more than 5 options',
  shouldHaveAtLeastOneOption: 'This field should have at least 1 option',
  invalidEmail: 'Please enter a valid email address, e.g. joe@mail.com',
  passwordValidation: 'Your password should be at least eight characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character',
  agreeToTermsAndConditions: 'You must agree to the terms and conditions',
  agreeToPrivacyPolicy: 'You must agree to the privacy policy',
}
