import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type QueryArgs = {
  therapist_id: string
  // This is not the user id, but current profile selected (lastSelectedProfile) of the user
  profile_id: string
  token: string
}

const zoomService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    afterDiscoveryCall: build.mutation<any, QueryArgs>({
      query: ({ token, ...data }) => ({
        url: apiRoutes.parent.afterDiscovery,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Token ${token}`,
        },
      }),
    }),
  }),
})

export const { useAfterDiscoveryCallMutation } = zoomService
