// Routing
import history from '../lib/history'
// Actions
import { setLoading, setError, clearError, setStatus, setModalLoading } from '../reducers/generalSlice'
import {
  setAllParents,
  setParentAllChildren,
  setAllChildren,
  setAllTherapists,
  setTherapistData,
  setAllAdvisors,
  setDocTherapistPersonal,
  setDocTherapistProfessional,
  setDocTherapistEducational,
  setChildScreenings,
  setChildTherapy,
  setChild,
  setChildRecords,
  setAllMeetings,
} from '../reducers/adminSlice'
import { setCurrentTherapist } from '../reducers/parentSlice'
// Api
import { adminApi } from '../api'
// Utils
import { downloadFile } from '../utils'
import { notify } from 'helpers'

export const adminActions = {
  getAllParents: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .getAllParents(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(setAllParents(res))
            dispatch(clearError())
            dispatch(setLoading(false))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  getChildProfile: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .getChildProfile(data)
        .then((res) => {
          dispatch(setChild(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('adminApi.getChildProfile', error)
          dispatch(setLoading(false))
        })
    } catch (e) {
      // console.log('adminApi.getChildProfile', error)
      dispatch(setLoading(false))
    }
  },
  getChildHistory: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .getChildHistory(data)
        .then((res) => {
          dispatch(setChildRecords(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('adminApi.getChildProfile', error)
          dispatch(setLoading(false))
        })
    } catch (e) {
      // console.log('adminApi.getChildProfile', error)
      dispatch(setLoading(false))
    }
  },
  provideDiscount: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      adminApi
        .provideDiscount(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  approveTherapist: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      adminApi
        .approveTherapist(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  declineTherapist: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      adminApi
        .declineTherapist(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  setAPIKey: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      adminApi
        .setAPIKey(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  updateTherapistInfo: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .updateTherapistInfo(data)
        .then((res) => {
          dispatch(setLoading(false))
          dispatch(setCurrentTherapist(res.data))
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  getAllAdvisors: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .getAllAdvisors(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(setAllAdvisors(res))
            dispatch(clearError())
            dispatch(setLoading(false))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  createAdvisor: (data) => (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .createAdvisor(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            const { allAdvisors } = getState().admin
            const updatedAllAdvisors = [...allAdvisors, res]
            dispatch(setAllAdvisors(updatedAllAdvisors))
            dispatch(clearError())
            dispatch(setLoading(false))
            dispatch(setStatus(200))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  resendAdvisor: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .resendAdvisor(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(clearError())
            dispatch(setLoading(false))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  deleteAdvisor: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      adminApi
        .deleteAdvisor(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  getAllMeetings: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .getAllMeetings(data)
        .then((res) => {
          dispatch(setAllMeetings(res?.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('adminApi.getAllMeetings', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('adminActions.getAllMeetings', error)
      dispatch(setLoading(false))
    }
  },
  retryZoomMeeting: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      adminApi
        .retryZoomMeeting(data)
        .then(() => {
          dispatch(setLoading(false))
          data?.fetchMeetings()
          notify('Meeting was successfully re-created!', false)
        })
        .catch((error) => {
          // // console.log('adminApi.retryZoomMeeting', error)
          dispatch(setLoading(false))
          notify(error?.response?.data?.message ?? 'Failed to re-create a meeting!', true)
        })
    } catch (error) {
      // console.log('adminActions.retryZoomMeeting', error)
      dispatch(setLoading(false))
    }
  },
}
