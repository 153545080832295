// Actions
import { setLoading } from '../reducers/generalSlice'
import { setHintModalDetails } from '../reducers/profileSlice'
// Api
import { profileApi } from '../api'

export const profileActions = {
  updateHintModalStep: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      profileApi
        .updateHintModalStep(data)
        .then((res) => {
          dispatch(setHintModalDetails({ isOpen: false, isFirstView: false }))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('profileApi.updateHintModalStep', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('profileActions.updateHintModalStep', error)
      dispatch(setLoading(false))
    }
  },
  getIPAddress: (data) => () => {
    try {
      profileApi
        .getIPAddress()
        .then((res) => {
          data?.setData(res?.data?.ip)
        })
        .catch((error) => {
          // console.log('profileApi.getIPAddress', error)
        })
    } catch (error) {
      // console.log('profileActions.getIPAddress', error)
    }
  },
}
