import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type ResultType = { data: Array<{ duration: number; price: number }> }
type QueryArgs = { therapist_id: string; token: string }

export const checkEmailExistenceServiceForAuthenticatedUser = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getSessionLengthPrice: build.mutation<ResultType, QueryArgs>({
      query: ({ therapist_id, token }) => {
        return {
          url: apiRoutes.getSessionLengthPrice,
          method: 'POST',
          headers: {
            Authorization: `Token ${token}`,
          },
          body: {
            therapist_id,
            profile_id: localStorage.getItem('lastSelectedProfile'),
          },
        }
      },
    }),
  }),
})

export const { useGetSessionLengthPriceMutation } = checkEmailExistenceServiceForAuthenticatedUser
