export const requestMessages: RequestMessage = {
  profile_updated: {
    en: 'Your profile has been updated successfully!',
    ar: 'تم تحديث ملفك الشخصي بنجاح!',
  },
  profile_updated_error: {
    en: 'There was an error updating your profile. Please try again.',
    ar: 'حدث خطأ أثناء تحديث ملفك الشخصي. يرجى المحاولة مرة أخرى.',
  },
  'The old password is not correct': {
    en: 'The old password is not correct',
    ar: 'كلمة المرور القديمة غير صحيحة',
  },
  unAuthorizedToViewZoomMeeting: {
    en: 'You are not authorized to view this zoom meeting',
    ar: 'أنت غير مصرح لك بالمشاركة في هذه الاجتماع',
  },
  meetingNotFound: {
    en: 'Meeting not found',
    ar: 'الاجتماع غير موجود',
  },
}

export type RequestMessage = {
  [key: string]: TranslationKeys
}

export type TranslationKeys = {
  [key: string]: string
}
