import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/type'

const rootState = (state: RootState) => state

export const selectQuestionnaire = createSelector([rootState], (state) => state.questionnaire)

export const selectCurrentMainStatementId = createSelector([selectQuestionnaire], (state) => state.currentMainStatementIndex)
export const selectQuestionnaireData = createSelector([selectQuestionnaire], (state) => state.questionnaireData)
export const selectOpenSubStatements = createSelector([selectQuestionnaire], (state) => state.subStatementsOpened)
export const selectCurrentSubStatementIndex = createSelector([selectQuestionnaire], (state) => state.currentSubStatementIndex)
export const selectUnansweredQuestions = createSelector([selectQuestionnaire], (state) => {
  return state.unansweredQuestions.filter((item) => item.subStatement.length !== 0).sort((a, b) => a.questionNumber - b.questionNumber)
})
