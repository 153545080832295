// Core
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Utils
import { useForm, useWatch } from 'react-hook-form'
import { convertBytes, getCountryFullName, returnLanguagesArray } from '../../../utils'
//MUI
import { Modal } from '@mui/material'
// Styles
import Styles from './styles.module.scss'
// Images
import { ReactComponent as AddIcon } from '../../../theme/assets/icons/add.svg'
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_updated.svg'
import { ReactComponent as EditIcon } from '../../../theme/assets/icons/edit_icon.svg'
import { ReactComponent as ClipIcon } from '../../../theme/assets/icons/paper_clip_blue_icon.svg'
import ava from '../../../theme/assets/images/avatar.svg'
//Actions
import { authActions, settingsActions, therapistActions } from '../../../actions'
//Components
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { TextAreaField, TextField, TextFieldAutocomplete, TextFieldMultiple, TextFieldPassword } from '../../GeneralComponents'
import { ChangeAvatar } from '../ChangeAvatar'
import { UploadlFiles } from '../UploadlFiles'
//Data
import { countries, languages } from '../../../data'
//Validation
import { schemaValid } from './validation'

import i18n from '../../../i18n'
import { englishValidationErrors } from '../../../locales/validations/englishValiationErrors'
import { arabicValidationErrors } from '../../../locales/validations/arabicValidationErrors'

import { useCheckEmailUserMutation } from './service'
import { useDebounce } from '../../../utils/hook/useDebounce'
import { userTypes } from '../../../constants'
import englishProfileContent from './locale/en.json'
import arabicProfileContent from './locale/ar.json'
import { useContentBundle } from 'hooks/useContentBundle'
import { Box } from 'components/Core'
import { useGetLanguage } from 'hooks/useGetLanguage'

export const UserSettings = ({ open, onClose }) => {
  const user = useSelector((state) => state.auth.user)
  const documents = useSelector((state) => state.therapist.documents)
  const loading = useSelector((state) => state.profile.loadingForSettings)

  const [isSpesificSetting, setIsSpesificSetting] = useState(false)
  const [isEditedData, setIsEditedData] = useState(false)

  const [isAvatarOpen, setIsAvatarOpen] = useState(false)
  const [isDocumentsOpen, setIsDocumentsOpen] = useState(false)

  const [documentsType, setDocumentsType] = useState('')

  const [valuesForLanguages, setValuesForLanguages] = useState([])
  const [valueForCountry, setValueForCountry] = useState({})
  const [checkMailForUser, { data }] = useCheckEmailUserMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language') ?? user?.user?.preferred_language)
  }, [user?.user?.preferred_language, localStorage.getItem('language')])

  useContentBundle({
    arabicContent: arabicProfileContent,
    englishContent: englishProfileContent,
    key: 'profileSettings',
  })

  const { t } = useContentBundle({
    arabicContent: arabicValidationErrors,
    englishContent: englishValidationErrors,
    key: 'validation',
  })
  const { direction } = useGetLanguage()
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    control,
    trigger,
    setValue,
    setError,
    resetField,
  } = useForm({
    mode: 'all',
    resolver: schemaValid(user?.user?.user_type, user?.user?.has_password, t),
    defaultValues: {
      full_name: '',
      bio: '',
      email: '',
      languages: [],
      country: '',
      password: '',
      old_password: '',
    },
  })

  useEffect(() => {
    if (user?.user?.user_type === 'therapist') {
      dispatch(therapistActions.getDocuments({ token: user?.token }))
    }
    dispatch(authActions.getUser(user))
  }, [])

  const returnSortedFiles = () => {
    const sortedDocuments = {
      personal: [],
      professional: [],
      educational: [],
    }
    documents?.map((document) => {
      if (document.file_type === 'personal') {
        sortedDocuments.personal.push(document)
      } else if (document.file_type === 'professional') {
        sortedDocuments.professional.push(document)
      } else {
        sortedDocuments.educational.push(document)
      }
    })

    return sortedDocuments
  }
  const watchFullName = useWatch({
    name: 'full_name',
    control,
  })

  const watchUserBio = useWatch({
    name: 'bio',
    control,
  })

  const watchEmail = useWatch({
    name: 'email',
    control,
  })

  const watchNewPassword = useWatch({
    name: 'password',
    control,
  })

  const watchCurrentPassword = useWatch({
    name: 'old_password',
    control,
  })

  useEffect(() => {
    const { name, email, languages, country, password, old_password, bio } = checkIsEdited()
    if (name || email || languages || country || password || old_password || bio) {
      setIsEditedData(true)
    } else {
      setIsEditedData(false)
    }
  }, [watchFullName, watchEmail, watchNewPassword, watchCurrentPassword, watchUserBio, getValues(['languages', 'country'])])

  useEffect(() => {
    const { password, old_password } = getValues()
    if (password) {
      if (user?.user?.has_password && !old_password) {
        setError('old_password', { type: 'custom', message: 'This field is required' })
      }
    }

    if (old_password) {
      if (user?.user?.has_password && !password) {
        setError('password', { type: 'custom', message: 'This field is required' })
      }
    }
  }, [watchNewPassword, watchCurrentPassword])

  const handleOpenAvatarEditor = () => {
    setIsSpesificSetting(true)
    setIsAvatarOpen(true)
  }

  const handleCloseAvatarEditor = () => {
    setIsSpesificSetting(false)
    setIsAvatarOpen(false)
  }

  const handleOpenDocumentsEditor = (type) => {
    setDocumentsType(type)
    setIsSpesificSetting(true)
    setIsDocumentsOpen(true)
  }

  const handleCloseDocumentsEditor = () => {
    setIsSpesificSetting(false)
    setIsDocumentsOpen(false)
  }

  useEffect(() => {
    const newLanguagesArray = []

    if (user?.user) {
      setValue('full_name', user.user?.full_name || '')
      setValue('email', user.user?.email || '')
      setValue('bio', user.user?.bio || '')
    }

    if (user.profile?.languages) {
      user.profile?.languages?.map((language) => {
        const valueObject = { value: language }
        newLanguagesArray.push(valueObject)
      })
    }

    if (user?.user) {
      const country = getCountryFullName(user.user?.country, true) || ''

      setValue('country', { value: country })
      setValueForCountry({ value: country })
    }

    setValue('languages', [...newLanguagesArray])
    setValuesForLanguages([...newLanguagesArray])
    trigger()
  }, [user])

  const checkArraysEqualty = (arr1, arr2) => {
    if (!arr2?.length) {
      return false
    }

    if (!arr1.length && arr2?.length) {
      return true
    }

    const sortedArr1 = arr1?.slice()?.sort()
    const sortedArr2 = arr2?.slice()?.sort()

    for (let i = 0; i < sortedArr1?.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return true
      }
    }

    return false
  }
  const checkIsEdited = () => {
    const { full_name, email, country, languages, password, old_password, bio } = getValues()
    const isNameEdited = full_name !== user?.user?.full_name
    const isEmailEdited = email !== user?.user?.email
    const isPasswordEdited = password !== ''
    const isOldPasswordEdited = old_password !== ''
    const isUserBioEdited = bio !== user?.user?.bio && bio !== ''

    let isCountryEdited
    if (user?.user?.user_type !== 'admin' && user?.user?.user_type !== 'supervisor') {
      isCountryEdited = country?.value && country?.value !== getCountryFullName(user.user?.country, true)
    }

    let isLanguagesEdited
    if (user?.user?.user_type === 'advisor') {
      isLanguagesEdited = checkArraysEqualty(
        user.profile?.languages,
        languages.map((lang) => lang?.value),
      )
    }

    const editingInfo = {
      name: isNameEdited,
      email: isEmailEdited,
      languages: isLanguagesEdited,
      country: isCountryEdited,
      password: isPasswordEdited,
      current_password: isOldPasswordEdited,
      bio: isUserBioEdited,
    }

    return editingInfo
  }

  const handleApply = (data) => {
    const { name, email, languages, country, password, current_password, bio } = checkIsEdited()

    const dataForRequest = {
      id: user?.user?.id,
      token: user?.token,
      update: {},
    }
    if (name) {
      dataForRequest.update.full_name = data.full_name
    }
    if (bio) {
      dataForRequest.update.bio = data.bio
    }

    if (email) {
      dataForRequest.update.email = data.email
    }

    if (country) {
      dataForRequest.update.country = data?.country?.value.split('_')[0]
    }

    if (password && current_password) {
      dataForRequest.update.password = data.password
      dataForRequest.update.old_password = data.old_password
    }

    if (password) {
      dataForRequest.update.password = data.password
    }

    if (languages) {
      const dataForLanguages = {
        id: user?.profile?.id,
        update: {
          languages: data?.languages?.map((language) => returnLanguagesArray(language.value)),
        },
        token: user.token,
      }

      dispatch(settingsActions.changeUserLanguages(dataForLanguages))
    }

    if (name || email || country || password || bio) {
      dispatch(settingsActions.changeUserInfo(dataForRequest))
      resetField('old_password')
      resetField('password')
    }
  }
  const isEmailExist = useCallback(() => {
    const email = getValues('email')
    if (email && !errors.email) checkMailForUser({ email, token: user?.token })
  }, [errors.email])

  useDebounce(isEmailExist, getValues('email'), watchEmail)

  useEffect(() => {
    if (data?.is_email_exists) {
      setError('email', { type: 'custom', message: 'This email already exists' })
    }
  }, [data?.is_email_exists])

  return (
    <Modal open={open} onClose={onClose}>
      <Box direction={direction} className={Styles.main_container}>
        {!isSpesificSetting && (
          <div className={Styles.header}>
            <h5>{t('profileSettings:profileSettings')}</h5>
            <CloseIcon className={Styles.header__icon} onClick={onClose} />
          </div>
        )}
        {loading ? (
          <Spinner />
        ) : (
          <div className={Styles.container_scroll}>
            {!isSpesificSetting && (
              <div className={Styles.items_container}>
                {user?.user?.user_type === 'therapist' && (
                  <div className={`${Styles.avatar_container} ClarityMask`}>
                    <div className={Styles?.avatar_container__edit}>
                      <EditIcon onClick={handleOpenAvatarEditor} />
                    </div>
                    <img src={user?.user?.profile_pic || ava} className={Styles.avatar_img} alt="avatar image" />
                  </div>
                )}
                <div className={`${Styles.item_container} ClarityMask`}>
                  <TextField register={register('full_name')} label={t('profileSettings:fullName')} error={errors.full_name} />
                </div>
                <div className={Styles.item_container}>
                  <TextField register={register('email')} label={t('profileSettings:email')} error={errors.email} />
                </div>
                {(user?.user?.user_type === 'parent' || user?.user?.user_type === 'therapist') && (
                  <div className={Styles.password_container}>
                    {user?.user?.has_password && (
                      <TextFieldPassword
                        register={register('old_password')}
                        label={t('profileSettings:currentPassword')}
                        placeholder={t('profileSettings:currentPassword')}
                        class={Styles.width_50}
                        error={errors.old_password}
                      />
                    )}
                    <TextFieldPassword
                      register={register('password')}
                      label={t('profileSettings:newPassword')}
                      placeholder={t('profileSettings:newPassword')}
                      error={errors?.password}
                      class={user?.user?.has_password ? Styles.width_50 : Styles.width_100}
                    />
                  </div>
                )}
                {user?.user?.user_type === 'advisor' && (
                  <TextFieldMultiple
                    register={register('languages')}
                    name="languages"
                    control={control}
                    options={languages}
                    value={valuesForLanguages}
                    setNewValue={setValuesForLanguages}
                    setValue={setValue}
                    freeSolo={true}
                    disabled={false}
                    error={errors.languages}
                    label={'Languages'}
                    placeholder="Select or enter languages"
                  />
                )}
                {user?.user?.user_type !== 'admin' && user?.user?.user_type !== 'supervisor' && (
                  <TextFieldAutocomplete
                    register={register('country')}
                    disablePortal={true}
                    manualSetValue={setValueForCountry}
                    defaultValue={valueForCountry}
                    name="country"
                    control={control}
                    options={countries}
                    setValue={setValue}
                    class_wrapper={Styles.width}
                    disabled={false}
                    error={errors.country}
                    class_field={Styles.form_field_country}
                    label={t('profileSettings:country')}
                  />
                )}

                {user?.user?.user_type === userTypes.therapist && (
                  <TextAreaField
                    register={register('bio')}
                    placeholder="Write something about you..."
                    customOnChange={(value) => {
                      setValue('bio', value)
                    }}
                    name="bio"
                    label="About Me"
                    control={control}
                    error={errors.bio}
                    maxlength={510}
                    value={watchUserBio}
                  />
                )}

                {user?.user?.user_type === 'therapist' && (
                  <div className={Styles.documents_conatiner}>
                    <p>Uploaded documents:</p>
                    <div className={Styles.documents_conatiner__documents_block}>
                      <div className={Styles.documents_conatiner__documents_block__title}>
                        <p onClick={() => handleOpenDocumentsEditor('personal')}>Personal</p>
                        <div className={Styles.icon}>
                          <AddIcon onClick={() => handleOpenDocumentsEditor('personal')} />
                        </div>
                      </div>
                      <div className={Styles.documents_conatiner__documents_block__container}>
                        {returnSortedFiles().personal?.map((document) => {
                          return (
                            <div className={Styles.documents_conatiner__documents_block__item}>
                              <ClipIcon />
                              <p>{document?.filename}</p>
                              <span>({convertBytes(document?.size)})</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className={Styles.documents_conatiner__documents_block}>
                      <div className={Styles.documents_conatiner__documents_block__title}>
                        <p onClick={() => handleOpenDocumentsEditor('professional')}>Professional</p>
                        <div className={Styles.icon}>
                          <AddIcon onClick={() => handleOpenDocumentsEditor('professional')} />
                        </div>
                      </div>
                      <div className={Styles.documents_conatiner__documents_block__container}>
                        {returnSortedFiles().professional?.map((document) => {
                          return (
                            <div className={Styles.documents_conatiner__documents_block__item}>
                              <ClipIcon />
                              <p>{document?.filename}</p>
                              <span>({convertBytes(document?.size)})</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className={Styles.documents_conatiner__documents_block}>
                      <div className={Styles.documents_conatiner__documents_block__title}>
                        <p onClick={() => handleOpenDocumentsEditor('educational')}>Educational</p>
                        <div className={Styles.icon}>
                          <AddIcon onClick={() => handleOpenDocumentsEditor('educational')} />
                        </div>
                      </div>
                      <div className={Styles.documents_conatiner__documents_block__container}>
                        {returnSortedFiles().educational?.map((document) => {
                          return (
                            <div className={Styles.documents_conatiner__documents_block__item}>
                              <ClipIcon />
                              <p>{document?.filename}</p>
                              <span>({convertBytes(document?.size)})</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {isAvatarOpen && <ChangeAvatar closeAvatar={handleCloseAvatarEditor} />}
            {isDocumentsOpen && <UploadlFiles closeEditor={handleCloseDocumentsEditor} type={documentsType} />}
          </div>
        )}
        {!isSpesificSetting && (
          <div className={Styles.footer}>
            <button className={isValid && isEditedData ? Styles.footer__btn : Styles.footer__btn_disabled} onClick={handleSubmit(handleApply)}>
              Apply changes
            </button>
          </div>
        )}
      </Box>
    </Modal>
  )
}
