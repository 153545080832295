import type { OnboardingStep } from 'defaultLayout/types'
import { Box, Button, CenterRow, Container, Flex, Text } from 'components/Core'
import { mainStatementAnswers } from 'constants/index'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { LinearProgress, TextField } from '@mui/material'
import { useQuestionnaire } from './useQuestionnaire'
import { RFor } from 'components/RFor/RFor'
import { SubStatement } from './SubStatement'
import { useGetLanguage } from 'hooks/useGetLanguage'

import { useTranslation } from 'react-i18next'
import ar from '../AddChild/locale.js/ar.json'
import en from '../AddChild/locale.js/en.json'
import { useEffect } from 'react'
import { mainStatementAnswer, translateMainAnswersQuestionnaire, translateQuestionnaire } from 'data/locale.data'
import { MainStatementAnswerKet } from 'utils/types/types'

type Props = {
  setOnboardingStep: (step: OnboardingStep) => void
  getSteps: (value: number | string) => void
  setAddChildStep: (step: number) => void
  isVariantA: boolean
  isVariantB: boolean
}

export const Mandatory = ({ setOnboardingStep, getSteps, isVariantA, isVariantB, setAddChildStep }: Props) => {
  const {
    currentMainStatementIndex,
    currentSubStatementIndex,
    questionnaireData,
    disableNextButton,
    subStatementsOpened,
    progress,
    // unansweredQuestions,
    onAnswerNo,
    onAnswerYes,
    goBack,
    next,
    setSubStatementAnswer,
    increaseSubStatementIndex,
  } = useQuestionnaire({ isVariantA, getSteps, setOnboardingStep, isVariantB, setAddChildStep })

  const { language, direction, isArabic } = useGetLanguage({
    userType: 'parent',
  })
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    i18n.addResourceBundle('en', 'add_child', en)
    i18n.addResourceBundle('ar', 'add_child', ar)
  }, [])

  return (
    <Container pt={40} justifyContent="flex-start" gap={60} height="100%" flexDirection="column">
      <CenterRow flexDirection={isArabic ? 'row-reverse' : 'row'} width={['95%', '95%', '90%', '50%']} justifyContent="space-between">
        <Button disabled={isVariantA && currentMainStatementIndex === 0} flexDirection={isArabic ? 'row-reverse' : 'row'} onClick={goBack} variant="ghost">
          {isArabic ? <ChevronRight /> : <ChevronLeft />}
          <Text fontSize={17}>{t('add_child:footer:back')}</Text>
        </Button>
        <Button flexDirection={isArabic ? 'row-reverse' : 'row'} disabled={disableNextButton} onClick={next} variant="ghost">
          <Text fontSize={17}>{t('add_child:footer:next')}</Text>
          {isArabic ? <ChevronLeft /> : <ChevronRight />}
        </Button>
      </CenterRow>
      <CenterRow width={['95%', '95%', '90%', '50%']}>
        <LinearProgress sx={{ width: '100%' }} color="secondary" variant="determinate" value={progress} />
      </CenterRow>
      <Container direction={direction} width={['100%', '100%', '90%', '600px']} flexDirection="column" gap={40}>
        {questionnaireData?.map((item, index) => (
          <>
            {currentMainStatementIndex === index && !subStatementsOpened && (
              <Container gap={[40]} key={index} flexDirection="column">
                <CenterRow flexDirection={'column'}>
                  {/* <Text variant="numberHolder">{index + 1}</Text> */}
                  <Text variant="heading4" textAlign="center" color="black.600">
                    {/* @ts-ignore */}
                    {translateQuestionnaire?.[index as keyof typeof translateQuestionnaire]?.[item.main_statement]?.[language]}
                  </Text>
                </CenterRow>
                <CenterRow px={2} gap={10} width={['100%']} flexDirection={['column', 'column', 'row']}>
                  <Button
                    onClick={() => {
                      onAnswerYes(index)
                    }}
                    width={['100%', '100%', 'auto']}
                    flexGrow={1}
                    variant={item.main_statement_answer === mainStatementAnswers.yes ? 'primary' : 'secondary'}
                  >
                    {mainStatementAnswer?.['Yes']?.[language as MainStatementAnswerKet]}
                  </Button>
                  <Button
                    width={['100%', '100%', 'auto']}
                    onClick={() => {
                      onAnswerNo(index)
                    }}
                    flexGrow={1}
                    variant={item.main_statement_answer === mainStatementAnswers.no ? 'primary' : 'secondary'}
                  >
                    {mainStatementAnswer?.['No']?.[language as keyof (typeof mainStatementAnswer)['No']]}
                  </Button>
                </CenterRow>
              </Container>
            )}

            {subStatementsOpened && currentMainStatementIndex === index && (
              <Container width={'100%'} gap={[40]} flexDirection="column">
                <RFor
                  data={item.sub_statements}
                  renderItem={(sub_statement, subStatementIndex) =>
                    currentSubStatementIndex === subStatementIndex && (
                      <SubStatement
                        mainStatementIndex={index}
                        setSubStatementAnswer={setSubStatementAnswer}
                        increaseSubStatementIndex={increaseSubStatementIndex}
                        language={language}
                        subStatementIndex={subStatementIndex}
                        {...sub_statement}
                        key={subStatementIndex}
                      />
                    )
                  }
                />
              </Container>
            )}
          </>
        ))}
      </Container>

      {/* {unansweredQuestions.length ? (
        <Container width="100%" justifyContent="center" mt={10} gap={20} flexDirection="column">
          <Text variant="heading3" textAlign="center" color="black.600">
            Unfinished Questions Under
          </Text>
          {unansweredQuestions.map((item, index) => (
            <Flex paddingBottom={10} key={index} gap={20}>
              <CenterRow borderRight={2} paddingRight={20} gap={10}>
                <Text width="200px">Question Number: </Text>
                <Text variant="numberHolder">{item?.questionNumber + 1}</Text>
              </CenterRow>
              <CenterRow gap={10}>
                <Text width="200px">unanswered count: </Text>
                <Text variant="numberHolder">{item?.subStatement?.length}</Text>
              </CenterRow>
            </Flex>
          ))}
        </Container>
      ) : null} */}
    </Container>
  )
}
