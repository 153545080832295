// Actions
import { setLoading, setModalLoading } from '../reducers/generalSlice'
import { setAllApplications, setOneApplication, setAllPatients, setHistory, setDocuments } from '../reducers/therapistSlice'
import { setChildNotes } from '../reducers/parentSlice'
// Api call
import { therapistApi } from '../api'
import { notify } from 'helpers'

export const therapistActions = {
  getAllApplications: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      therapistApi
        .getAllScreenings(data)
        .then((res) => {
          dispatch(setAllApplications(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('therapistApi.getAllApplications', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('therapistActions.getAllApplications', error)
      dispatch(setLoading(false))
    }
  },
  getOneApplication: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      therapistApi
        .getOneScreening(data)
        .then((res) => {
          dispatch(setOneApplication(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('therapistApi.getOneApplication', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('therapistActions.getOneApplication', error)
      dispatch(setLoading(false))
    }
  },
  acceptApplication: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      therapistApi
        .acceptApplication(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          data?.action()
          notify(data?.notification, false)
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  declineApplication: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      therapistApi
        .declineApplication(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          data?.action()
          notify(data?.notification, false)
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  getAllPatients: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      therapistApi
        .getAllPatients(data)
        .then((res) => {
          dispatch(setAllPatients(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('therapistApi.getAllPatients', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('therapistActions.getAllPatients', error)
      dispatch(setLoading(false))
    }
  },
  getHistory: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      therapistApi
        .getHistory(data)
        .then((res) => {
          dispatch(setHistory(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('therapistApi.getHistory', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('therapistActions.getHistory', error)
      dispatch(setLoading(false))
    }
  },
  getDocuments: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      therapistApi
        .getDocuments(data)
        .then((res) => {
          dispatch(setDocuments(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('therapistApi.getDocuments', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('therapistActions.getDocuments', error)
      dispatch(setLoading(false))
    }
  },
  getDocumentsForAdmins: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      therapistApi
        .getDocumentsForAdmins(data)
        .then((res) => {
          dispatch(setDocuments(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('therapistApi.getDocumentsForAdmins', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('therapistActions.getDocumentsForAdmins', error)
      dispatch(setLoading(false))
    }
  },
  createNote: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      therapistApi
        .createNote(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          // console.log('therapistActions.createNote', e)
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  getNotes: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      therapistApi
        .getNotes(data)
        .then((res) => {
          dispatch(setLoading(false))
          dispatch(setChildNotes(res.data))
        })
        .catch((error) => {
          // console.log('therapistApi.getNotes', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('therapistActions.getNotes', error)
      dispatch(setLoading(false))
    }
  },
}
