// Core
import { shape, string, bool, number } from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'

// Styles
import Styles from './styles.module.scss'
import StylesNewMessage from '../../MessagesHistory/NewMessageBar/styles.module.scss'
// Components
import { ReactComponent as CheckIcon } from '../../../../../theme/assets/icons/check_updated.svg'
import { Spinner } from '../../Spinner'
//Helpers
import { getFormatedLocalTime } from '../../../../../helpers'

//Utils
import { convertBytes, returnContent } from '../../../../../utils'
//Icons
import { ReactComponent as FileIcon } from '../../../../../theme/assets/icons/file_new.svg'

export const SentMessageInstance = ({ messageInfo }) => {
  const { isViewed, content, timestamp, attachment } = messageInfo
  const [isLoading, setIsLoading] = useState(false)

  const user = useSelector((state) => state.auth.user)

  const handleOpenFile = async (url, filename) => {
    try {
      setIsLoading(true)

      const response = await fetch(url)
      const blob = await response.blob()
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = filename
      link.click()
      URL.revokeObjectURL(objectUrl)

      setIsLoading(false)
    } catch (error) {
      // console.error('Error downloading file:', error);
      setIsLoading(false)
    }
  }

  const getPreviewImg = (fileType, filePreview) => {
    switch (fileType) {
      case 'png':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      case 'jpg':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      default:
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <FileIcon />
          </div>
        )
    }
  }

  const thumbs = () => {
    return (
      <div className={StylesNewMessage.preview__item} key={attachment.name} onClick={() => handleOpenFile(attachment.url, attachment.filename)}>
        <div className={StylesNewMessage.preview__item__preview}>
          <>
            {isLoading ? <Spinner /> : <>{getPreviewImg(attachment?.extension, attachment?.url)}</>}
            <div className={StylesNewMessage.preview__item__preview__description}>
              <p className={StylesNewMessage.preview__item__preview__description__name}>{attachment.filename}</p>
              <p className={StylesNewMessage.preview__item__preview__description__size}>{convertBytes(attachment.size)}</p>
            </div>
          </>
        </div>
      </div>
    )
  }

  const formattedTime = timestamp.toLocaleString('en-US', { timeZone: user.timeZone })

  return (
    <div className={Styles.sentMessageInstance}>
      <div className={Styles.sentMessageInstance__content}>
        {/* {isViewed && (
          <div className={Styles.sentMessageInstance__content__icon}>
            <CheckIcon />
          </div>
        )} */}
        {attachment ? thumbs() : <p className={Styles.sentMessageInstance__content__message}>{returnContent(content)}</p>}
      </div>
      <p className={Styles.sentMessageInstance__time}>{getFormatedLocalTime(formattedTime)}</p>
    </div>
  )
}
