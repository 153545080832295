/**
 * Functional component for handling onboarding questions.
 *
 * @component
 */

//Core
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as ArrowLeft } from '../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_ violet_icon.svg'
//Data
import { adultOnboardingQuestions, mandatory_questions_parent_onboarding } from '../../../data'
import { SKIPPED_VALUE } from '../../../data/enums'
// Instruments
import { useForm } from 'react-hook-form'
// Components
import { TextAreaField } from '../../GeneralComponents'
import { ConfirmationPopup } from '../../GeneralComponents'
// Actions
import { advisorOnbordingActions } from '../../../actions'
import { decreaseCurrentQuestionId, increaseCurrentQuestionId, setAdditionalAdvisorComments, setShowAdvisorCommentSection } from '../../../reducers/advisorOnbordingSlice'
import { selectAdditionalAdvisorComments, selectCurrentQuestionId, selectFilledQuestions, selectShowAdvisorCommentSection } from './selectors'

export const Questions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const additionalAdvisorComments = useSelector(selectAdditionalAdvisorComments)
  const showAdvisorCommentSection = useSelector(selectShowAdvisorCommentSection)
  const filledQuestions = useSelector(selectFilledQuestions)
  const currentQuestionId = useSelector(selectCurrentQuestionId)
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')
  const consultation_id = searchParams.get('consultation_id')
  const savedOnboardingQuestions = localStorage.getItem(consultation_id)

  // const [currentQuestionId, setCurrentQuestionId] = useState(0)
  const [onboardingQuestions, setOnboardingQuestions] = useState([])

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  const showAdditionalCommentSection = (value) => dispatch(setShowAdvisorCommentSection(value))

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
  }, [searchParams.get('lng')])

  // const [isOpenDecline, setIsOpenDecline] = useState(false)

  // const handleOpenDeclinePopup = () => setIsOpenDecline(true)
  // const handleCloseDeclinePopup = () => setIsOpenDecline(false)

  // console.log()
  const { handleSubmit, control, getValues, register } = useForm({
    mode: 'all',
    defaultValues: {
      additionalAdvisorComments,
      ...JSON.parse(savedOnboardingQuestions ?? '{}'),
    },
  })

  /**
   * Navigates to the previous page.
   */
  const goBack = () => {
    if (showAdvisorCommentSection) {
      showAdditionalCommentSection(false)
    } else {
      if (currentQuestionId !== 0) {
        dispatch(decreaseCurrentQuestionId())
      } else {
        navigate('/admin_panel/advisor/applications')
      }
    }
  }
  /**
   * Initializes onboarding questions based on the profile type.
   */
  useEffect(() => {
    if (filledQuestions.length) {
      setOnboardingQuestions(filledQuestions)
    } else {
      if (type === 'adult') {
        setOnboardingQuestions(adultOnboardingQuestions)
      } else {
        const childQuestionnaire = mandatory_questions_parent_onboarding?.map((item) => ({ id: item?.id, category: item?.category, main_statement: item?.main_statement }))
        setOnboardingQuestions(childQuestionnaire)
      }
    }
  }, [type, JSON.stringify(filledQuestions)])

  /**
   * Adds a beforeunload event listener to warn users about unsaved changes.
   */
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.returnValue = 'Are you sure you want to leave? Any unsaved changes will be lost.'
  //     return event.returnValue
  //   }

  //   window.addEventListener('beforeunload', handleBeforeUnload)

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //   }
  // }, [])

  /**
   * Advances to the next onboarding question or submits the final answer.
   */

  const next = (data) => {
    // console.log(data)
    const updatedQuestion = {
      ...onboardingQuestions[currentQuestionId],
      advisor_comment: data[`${currentQuestionId}_advisor_comment`] || '',
    }
    const additionalAdvisorComments = getValues('additionalAdvisorComments')
    const finalQuestions = [...onboardingQuestions.slice(0, currentQuestionId), updatedQuestion, ...onboardingQuestions.slice(currentQuestionId + 1)]
    setOnboardingQuestions(finalQuestions)

    localStorage.setItem(consultation_id, JSON.stringify(data))
    // localStorage.setItem(`saved_additional_advisor_comments_${consultation_id}`, JSON.stringify(additionalAdvisorComments))

    const amountofQuestions = type === 'adult' ? adultOnboardingQuestions?.length : mandatory_questions_parent_onboarding?.length
    if (showAdvisorCommentSection) {
      dispatch(advisorOnbordingActions.setQuestions(finalQuestions))
      dispatch(setAdditionalAdvisorComments(additionalAdvisorComments))
      navigate(`summary?id=${consultation_id}`)
    }
    if (currentQuestionId + 1 === amountofQuestions) {
      showAdditionalCommentSection(true)
    } else {
      dispatch(increaseCurrentQuestionId())
    }
  }

  /**
   * Declines the onboarding application and navigates back to the applications page.
   *
   * This function handles the process of declining an onboarding application. It gathers the necessary data,
   * such as answers to the onboarding questions, and dispatches an action to decline the application.
   *
   * @param {Object} data - The data object containing the reason for declining the application.
   * @param {string} data.reason - The reason for declining the application.
   */

  // const contentForDeclineApplicationPopup = {
  //   title: 'Are you sure you want to decline the onboarding?',
  //   secondaryText: {
  //     text: 'What are reasons for declining?',
  //     style: Styles.popup_text,
  //   },
  //   buttons: {
  //     direction: 'vertical',
  //     content: [
  //       {
  //         id: 1,
  //         text: 'Continue',
  //         style: Styles.btn_decline_therapist__delete,
  //         onClick: declineApplication,
  //       },
  //       {
  //         id: 2,
  //         text: 'Cancel',
  //         style: Styles.btn_decline_therapist__cancel,
  //         onClick: handleCloseDeclinePopup,
  //       },
  //     ],
  //   },
  // }
  return (
    <div className={Styles.main_container}>
      {/* <div className={Styles.header} onClick={handleOpenDeclinePopup}>
        End onboarding early
        <CloseIcon />
      </div> */}
      <div className={Styles.main_container__onboarding_container}>
        <div className={Styles.main_container__content}>
          {!showAdvisorCommentSection ? (
            onboardingQuestions?.map(
              (item) =>
                currentQuestionId === item?.id && (
                  <div className={Styles.main_container__content__item} key={item?.id}>
                    <h4>
                      {item?.id + 1}. {item?.category}
                    </h4>
                    <h6>{item?.main_statement}</h6>
                    <TextAreaField
                      defaultValue={item?.advisor_comment || ''}
                      control={control}
                      placeholder={'Your comment here'}
                      inputClass={Styles.input_text_area}
                      class={Styles.input_container}
                      name={`${item?.id}_advisor_comment`}
                      label={' '}
                    />
                  </div>
                ),
            )
          ) : (
            <div className={Styles.main_container__content__item}>
              <h4>Any additional comment?</h4>
              <TextAreaField
                defaultValue={additionalAdvisorComments}
                control={control}
                placeholder={'Additional comment here'}
                inputClass={Styles.input_text_area}
                class={Styles.input_container}
                name="additionalAdvisorComments"
                label={' '}
              />
            </div>
          )}
          <div className={Styles.btns}>
            <div
              className={`${
                onboardingQuestions?.length < (type === 'adult' ? adultOnboardingQuestions?.length : mandatory_questions_parent_onboarding?.length)
                  ? Styles.btns__back_disabled
                  : Styles.btns__back
              }`}
              onClick={goBack}
            >
              <ArrowLeft />
              <span>Back</span>
            </div>
            <button className={true ? Styles.btns__next : Styles.btns__next_disable} onClick={handleSubmit(next)}>
              Continue
            </button>
          </div>
        </div>
      </div>
      {/* {isOpenDecline && (
        <ConfirmationPopup open={isOpenDecline} onClose={handleCloseDeclinePopup} content={contentForDeclineApplicationPopup} width={'436px'} isDeclinePopup={true} />
      )} */}
    </div>
  )
}
