import type { ReactNode } from 'react'
import { BoxProps, CenterRow } from 'components/Core'
import { theme } from 'utils/theme'
interface Props extends BoxProps {
  children: ReactNode
  onClick?: () => void
  selected: boolean
}
export const OutlinedCard = ({ children, onClick, selected, ...rest }: Props) => {
  return (
    <CenterRow
      backgroundColor={selected ? theme.colors.typePurpure[200] : ''}
      color={selected ? 'white' : ''}
      cursor="pointer"
      gap="10px"
      flexDirection="column"
      borderRadius={1}
      boxShadow={'base'}
      justifyContent="center"
      border={2}
      onClick={onClick}
      {...rest}
    >
      {children}
    </CenterRow>
  )
}
