import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import type { MainStatementAnswerPayload, QuestionnaireState, SubStatementAnswerPayload, UnansweredQuestions } from './types'
import { structuredQuestionnaire } from 'data/locale.data'
const questionnaire = localStorage.getItem('questionnaire')
const initialState: QuestionnaireState = {
  currentMainStatementIndex: 0,
  questionnaireData: questionnaire ? JSON.parse(questionnaire) : structuredQuestionnaire,
  subStatementsOpened: false,
  currentSubStatementIndex: 0,
  unansweredQuestions: [],
}

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setCurrentMainStatementIndex: (state, action: PayloadAction<number>) => {
      state.currentMainStatementIndex = action.payload
    },
    setMainStatementAnswer: (state, { payload }: PayloadAction<MainStatementAnswerPayload>) => {
      state.questionnaireData[payload.index].main_statement_answer = payload.answer
    },
    toggleSubStatements: (state, action: PayloadAction<boolean>) => {
      state.subStatementsOpened = action.payload
    },
    setCurrentSubStatementIndex: (state, action: PayloadAction<number>) => {
      state.currentSubStatementIndex = action.payload
    },
    setSubStatementAnswer: (state, { payload }: PayloadAction<SubStatementAnswerPayload>) => {
      state.questionnaireData[payload.mainStatementIndex].sub_statements[payload.subStatementIndex].sub_statement_answer = payload.subStatementAnswer
    },
    skipAllSubStatementsAnswer: (state, { payload }: PayloadAction<number>) => {
      state.questionnaireData[payload].sub_statements.forEach((subStatement) => {
        subStatement.sub_statement_answer = 'Skipped'
      })
    },
    setUnansweredQuestions: (state, { payload }: PayloadAction<UnansweredQuestions>) => {
      const index = state.unansweredQuestions.findIndex((item) => item.questionNumber === payload.questionNumber)
      if (index !== -1) {
        state.unansweredQuestions.splice(index, 1, payload)
      } else {
        state.unansweredQuestions.push(payload)
      }
    },
  },
})

export const useQuestionnaireSlice = () => {
  const dispatch = useDispatch()
  const setCurrentMainStatementIndex = (id: number) => dispatch(actions.setCurrentMainStatementIndex(id))
  const { actions } = questionnaireSlice
  const setMainStatementAnswer = ({ index, answer }: MainStatementAnswerPayload) => dispatch(actions.setMainStatementAnswer({ index, answer }))
  const toggleSubStatements = (value: boolean) => dispatch(actions.toggleSubStatements(value))
  const setCurrentSubStatementIndex = (index: number) => dispatch(actions.setCurrentSubStatementIndex(index))
  const setSubStatementAnswer = (payload: SubStatementAnswerPayload) => dispatch(actions.setSubStatementAnswer(payload))
  const resetSubStatementsIndex = () => dispatch(actions.setCurrentSubStatementIndex(0))
  const skipAllSubStatementsAnswer = (index: number) => dispatch(actions.skipAllSubStatementsAnswer(index))
  const setUnansweredQuestions = (payload: UnansweredQuestions) => dispatch(actions.setUnansweredQuestions(payload))
  return {
    actions,
    dispatch,
    setCurrentMainStatementIndex,
    setMainStatementAnswer,
    toggleSubStatements,
    setCurrentSubStatementIndex,
    setSubStatementAnswer,
    resetSubStatementsIndex,
    skipAllSubStatementsAnswer,
    setUnansweredQuestions,
  }
}

export const questionnaireReducers = questionnaireSlice.reducer
