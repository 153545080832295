import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Options {
  englishContent: any
  arabicContent: any
  key: string
}
export const useContentBundle = ({ englishContent, arabicContent, key }: Options) => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.addResourceBundle('en', key, englishContent)
    i18n.addResourceBundle('ar', key, arabicContent)
  }, [])
  return {
    t,
    i18n,
  }
}
