// Core
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Routing
import { useLocation, Link } from 'react-router-dom'
// Instruments
import queryString from 'query-string'
// Actions
import { authActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { Loader } from '../../GeneralComponents'

export const ConfirmEmail = () => {
  const dispatch = useDispatch()
  const message = useSelector((state) => state.general.message)
  const loading = useSelector((state) => state.general.loading)
  const user = useSelector((state) => state.auth.user)

  const location = useLocation()

  useEffect(() => {
    const { token } = queryString.parse(location.search)

    if (token) {
      const data = {
        token,
      }
      dispatch(authActions.sendConfirmEmail(data))
    }
  }, [])

  const sendAnotherLink = () => {
    const searchParams = new URLSearchParams(location.search)
    const encodedEmail = searchParams.get('email')

    if (encodedEmail) {
      const email = decodeURIComponent(encodedEmail)
      // console.log(email)
      const data = {
        email,
      }
      dispatch(authActions.sendVerifyEmail(data))
    }
  }

  if (loading) {
    return (
      <section className={Styles.confirm_email}>
        <Loader class={Styles.loader} />
      </section>
    )
  }

  return (
    <section className={Styles.confirm_email}>
      {user.is_active ? (
        <div className={Styles.confirm_email_container}>
          <div className={Styles.success_message}>
            <span />
            <span>{message}</span>
          </div>
          <div className={Styles.clarification}>
            <span>{'Go in account'}</span>
            <Link to="/login" className={Styles.login_btn}>
              {'Log in'}
            </Link>
          </div>
        </div>
      ) : (
        <div className={Styles.confirm_email_container}>
          <h3 className={Styles.confirm_email_caption}>{'Registration link expired'}</h3>
          <p className={Styles.confirm_email_msg}>
            <span>{'Your registration link has expired, because you haven’t used it. '}</span>
            <span>{'Link expires every 24 hours and can only be used once. You can create new one by clicking on request button.'}</span>
          </p>
          <button onClick={() => sendAnotherLink()} className={Styles.submit_btn}>
            {'Request another link'}
          </button>
        </div>
      )}
    </section>
  )
}
