//Core
import { useEffect, useMemo } from 'react'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as UserIcon } from '../../../../theme/assets/icons/user.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { translateTherapistProfQualities } from '../../../../data/locale.data'
import { DiscoveryCallSkipConfirmation } from './DiscoveryCallSkipConfirmation'
import { useDisclosure } from 'hooks/useDisclosure'
import { Box } from 'components/Core'
import { notify } from 'helpers'
import { sessionStatus } from 'constants/index'

export const TherapistCard = ({
  discovery,
  actionForDetailsPopup,
  openDiscoveryCallPopup,
  setCalComLink,
  setDeclinePopupOpen,
  setDiscoveryId,
  acceptTherapist,
  setTherapistId,
  language,
  direction,
}) => {
  const therapist = discovery?.therapist
  const { t } = useTranslation()

  useEffect(() => {
    i18n.addResourceBundle('en', 'therapist_card', en)
    i18n.addResourceBundle('ar', 'therapist_card', ar)
  }, [])

  //DECLINE THERAPIST POPUP
  const handleOpenDeclinePopup = (event) => {
    event.stopPropagation()
    setDiscoveryId(discovery?.id)
    setDeclinePopupOpen(true)
  }

  //ACCEPT THERAPIST
  const handleAcceptTherapist = () => {
    acceptTherapist(discovery?.id)
  }

  //THERAPIST INFO POPUP
  const handleOpenDetailsPopup = (event) => {
    event.stopPropagation()
    setTherapistId(therapist.id)
    actionForDetailsPopup(true)
  }

  //DISCOVERY CALL POPUP
  const handleOpenDiscoveryCallPopup = (event, link) => {
    event.stopPropagation()
    if (link) {
      setCalComLink(link)
      openDiscoveryCallPopup(true)
    } else {
      notify('Discovery call link not available')
    }
  }
  const { onOpen, onClose, open } = useDisclosure()

  const discoveryCallCompleted = useMemo(() => discovery?.calcom_session?.get_status === sessionStatus.Completed, [discovery?.calcom_session?.get_status])
  const discoveryCallInProgress = useMemo(
    () => [sessionStatus['In progress'], sessionStatus.Planned].includes(discovery?.calcom_session?.get_status),
    [discovery?.calcom_session?.get_status],
  )
  return (
    <Box direction={direction} className={Styles.main}>
      <div className={Styles.header}>
        <div className={Styles.header__photo}>{therapist?.user?.profile_pic ? <img src={therapist?.user?.profile_pic} /> : <UserIcon />}</div>
        <div className={Styles.status}>
          {/* {discovery.parent_status === 'waiting' ? (
            <p className={Styles.status_waiting}>{t('therapist_card:statuses:pending')}</p>
          ) : (
            <p className={Styles.status_accepted}>{translateTherapistStatuses[discovery?.parent_status][language]?.toUpperCase()}</p>
          )} */}
        </div>
      </div>
      <div className={Styles.info}>
        <div className={Styles.info__name}>{therapist?.user?.full_name}</div>
        <span>{therapist?.professional_qualities?.map((value) => translateTherapistProfQualities?.[value]?.[language] || value)?.join(', ')}</span>
        <p onClick={(e) => handleOpenDetailsPopup(e, therapist?.id)}>{t('therapist_card:view_profile')}</p>
      </div>
      <div className={Styles.btns}>
        <button
          className={Styles.btns__call}
          style={{
            borderColor: discovery?.calcom_session?.get_status ? '#C7BCCE' : '#8450A0',
            color: discovery?.calcom_session?.get_status ? '#C7BCCE' : '#8450A0',
            cursor: discovery?.calcom_session?.get_status && 'default',
          }}
          disabled={discovery?.calcom_session?.get_status}
          onClick={(e) => handleOpenDiscoveryCallPopup(e, discovery?.therapist?.discovery_link)}
        >
          {t('therapist_card:book_discovery_call')}
        </button>
        {discovery?.parent_status != 'accepted' && (
          <div style={{ gap: 10 }} className={Styles.btns__actions}>
            <div
              style={{ flexGrow: 1, textAlign: 'center' }}
              onClick={(e) => {
                if (discoveryCallCompleted) {
                  handleAcceptTherapist()
                } else {
                  onOpen()
                }
              }}
              className={Styles.accept_btn}
            >
              {t('therapist_card:actions:accept')}
            </div>
            <div style={{ flexGrow: 1, textAlign: 'center' }} onClick={(e) => handleOpenDeclinePopup(e)} className={Styles.decline_btn}>
              {t('therapist_card:actions:decline')}
            </div>
          </div>
        )}
      </div>
      <DiscoveryCallSkipConfirmation
        variant={discoveryCallInProgress ? 'skipDiscoveryCallMeeting' : 'skipDiscoveryBooking'}
        direction={direction}
        command={handleAcceptTherapist}
        open={open}
        onClose={onClose}
      />
    </Box>
  )
}
