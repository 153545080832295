import { createSelector } from '@reduxjs/toolkit'

const rootState = (state: any) => state

export const selectPatients = createSelector(rootState, (state) => state.patients)

const selectTherapistState = createSelector([rootState], (state) => state.therapist)

export const selectAllPatients = createSelector([selectTherapistState], (state) => {
  const allPatients = state?.allPatients
  const now = new Date().getTime()
  return [...allPatients]?.sort((a: any, b: any) => {
    if (!a?.calcom_session) return 1
    if (!b?.calcom_session) return -1
    const dateA = Math.abs(new Date(a?.calcom_session?.start_time).getTime() - now)
    const dateB = Math.abs(new Date(b?.calcom_session?.start_time).getTime() - now)
    return dateA - dateB
  })
})
