import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from '../actions'
import { deleteCookie, deleteLocalStorage, getCookie } from '../utils'
import { useLocation, useNavigate } from 'react-router-dom'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const userStorage = JSON.parse(getCookie('user'))

    if (userStorage?.user?.id) {
      dispatch(authActions.getUser(userStorage))
      setCurrentUser(userStorage)
      let dashboardLink
      switch (userStorage?.user?.user_type) {
        case 'admin':
          dashboardLink = '/admin_panel/admin/parents'
          break
        case 'advisor':
          dashboardLink = '/admin_panel/advisor/applications'
          break
        case 'therapist':
          dashboardLink = '/profile/therapist/applications'
          break
        case 'parent':
          dashboardLink = '/profile/parent/therapy'
          break
      }
      if (pathname === '/' || pathname === '/login') {
        navigate(dashboardLink)
      }
    } else {
      deleteCookie('user')
      deleteLocalStorage('token')
      navigate('/login')
      localStorage.removeItem('impersonated')
    }
  }, [pathname])

  return <AuthContext.Provider value={{ currentUser }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
