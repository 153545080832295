//Core
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
//Styles
import Styles from './Styles.module.scss'
//Components
import { CheckboxField, TextField, TextFieldPassword } from '../../../GeneralComponents'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
//Form
import { useForm, useWatch } from 'react-hook-form'
import { schemaValid } from './validator'
// Utils
import dayjs from 'dayjs'
import { deleteLocalStorage, getLocalStorage, userTimeZone, returnLanguagesArray } from '../../../../utils'
// Data
import { mandatory_questions_parent_onboarding } from '../../../../data'
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME } from '../../../../data/enums'
// Actions
import { authActions } from '../../../../actions'
// Constants
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../../../constants'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { useCheckPromoCodeMutation } from './service'
import { useDebounce } from './useDebounce'

import { englishValidationErrors } from '../../../../locales/validations/englishValiationErrors'
import { arabicValidationErrors } from '../../../../locales/validations/arabicValidationErrors'
import { Box, CenterRow } from 'components/Core'
import { CircularProgress } from '@mui/material'

export const ParentFormRegistration = ({ setOnboardingStep, isVariantA, setAddChildStep }) => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [checkPromoCode, { data, isLoading }] = useCheckPromoCodeMutation()

  const [childInformation, setChildInformarion] = useState({})
  const [questionnaire, setQuestionnaire] = useState([])
  const [familyDynamics, setFamilyDynamics] = useState([])
  const [parentalWellbeing, setParentalWellbeing] = useState([])

  const loading = useSelector((state) => state.general.loading)

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'form_registration', en)
    i18n.addResourceBundle('ar', 'form_registration', ar)
    i18n.addResourceBundle('en', 'validation', englishValidationErrors)
    i18n.addResourceBundle('ar', 'validation', arabicValidationErrors)
  }, [])

  useEffect(() => {
    const childInformation = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    setChildInformarion(childInformation)
    const questionnaire = localStorage.getItem('questionnaire')
    setQuestionnaire(JSON.parse(questionnaire ?? '[]'))
    setFamilyDynamics(JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.FAMILY_DYNAMICS)))
    setParentalWellbeing(JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENTAL_WELLBEING)))
  }, [])

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    getValues,
    control,
    setError,
  } = useForm({
    mode: 'all',
    resolver: schemaValid(t),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_2: '',
      is_agree_terms_conditions: false,
      is_agree_to_receive_email: true,
      is_agree_privacy_policy: false,
      promo_code: '',
    },
  })

  /**Check Promo Code */
  const handlePromoCodeCheck = () => {
    const promoCode = getValues('promo_code')
    if (promoCode) {
      checkPromoCode(promoCode.toUpperCase())
    }
  }
  const watchPromoCode = useWatch({
    name: 'promo_code',
    control,
  })

  useDebounce(handlePromoCodeCheck, getValues('promo_code'), watchPromoCode)
  const promoCodeError = useMemo(() => {
    const promoCode = getValues('promo_code')
    if (!promoCode) {
      return null
    }
    if (data?.errorMessage) return { message: t(`form_registration:errorMessages:${data?.errorMessage}`) }
    return null
  }, [data?.errorMessage, watchPromoCode])

  /**PROMO CODE CHECK END */

  const handleCreateAccount = (data) => {
    if (data.errorMessage) {
      setError('promo_code', {
        message: data.errorMessage,
      })
    }
    // If PromoCode exist convert it to upper case to make it consistent with back
    const promo_code = data?.promo_code ? data?.promo_code?.toString()?.toUpperCase() : null

    const finalObject = {
      payload: {
        parent_info: {
          full_name: `${data?.first_name} ${data?.last_name}`,
          email: data?.email,
          password: data?.password,
          password_2: data?.password_2,
          existing_account: false,
          is_agree_terms_conditions: data?.is_agree_terms_conditions,
          is_agree_to_receive_email: data?.is_agree_to_receive_email,
          is_agree_privacy_policy: data?.is_agree_privacy_policy,
          // Parent preferred language
          preferred_language: selectedLanguage,
          promo_code,
          time_zone: userTimeZone,
        },
        child_info: {
          ...childInformation?.child_info,
          birth_date: dayjs(childInformation?.child_info?.birth_date).format('YYYY-MM-DD'),
          languages: childInformation?.child_info?.languages?.map((i) => returnLanguagesArray(i))?.filter((i) => i !== null),
          behavior: childInformation?.child_info?.behavior?.map((i) => i?.value),
          concerns: childInformation?.child_info?.concerns?.map((i) => i?.value),
        },
        questionnaire: [...questionnaire, { tell_us_more: childInformation?.additional_info }],
        family_dynamics: familyDynamics ?? [],
        parental_wellbeing: parentalWellbeing ?? [],
      },
      action: () => {
        deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.FAMILY_DYNAMICS)
        deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENTAL_WELLBEING)
        deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE)
        deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING)

        dispatch(authActions.loginUser({ email: finalObject.payload.parent_info.email, password: finalObject.payload.parent_info.password }))
      },
    }
    dispatch(
      authActions.postQuestionnaireForParent({ ...finalObject, pendingMessage: t('form_registration:pendingMessage'), successMessage: t('form_registration:successMessage') }),
    )
  }

  const handleClickLink = (e, link) => {
    e.preventDefault()
    window.open(link, '_blank')
  }

  return (
    <>
      <Box py={20} height={['auto']} className={Styles.main_container}>
        <h4>{t('form_registration:header')}</h4>
        <div style={{ textAlign: selectedLanguage === 'en' ? 'left' : 'right' }} className={Styles.main_container__form}>
          <div className={`${Styles.main_container__form__two_fields} ${selectedLanguage === 'ar' && Styles.two_fields_ar}`}>
            <TextField
              label={t('form_registration:fields:first_name:label')}
              placeholder={t('form_registration:fields:first_name:placeholder')}
              error={errors?.first_name}
              register={register('first_name')}
              error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
              inputClass={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
            />
            <TextField
              label={t('form_registration:fields:last_name:label')}
              placeholder={t('form_registration:fields:last_name:placeholder')}
              error={errors?.last_name}
              error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
              register={register('last_name')}
              inputClass={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
            />
          </div>
          <TextField
            inputClass={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
            label={t('form_registration:fields:email:label')}
            placeholder={'youremail@gmail.com'}
            error={errors?.email}
            error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
            register={register('email')}
          />
          <div className={`${Styles.main_container__form__two_fields} ${selectedLanguage === 'ar' && Styles.two_fields_ar}`}>
            <TextFieldPassword
              label={t('form_registration:fields:password:label')}
              placeholder={t('form_registration:fields:password:placeholder')}
              class={selectedLanguage === 'ar' && Styles.password_ar}
              error={errors?.password}
              error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
              icon_class={selectedLanguage === 'ar' && Styles.icon_ar}
              register={register('password')}
              inputClass={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
            />
            <TextFieldPassword
              label={t('form_registration:fields:password_2:label')}
              placeholder={t('form_registration:fields:password_2:placeholder')}
              error={errors?.password_2}
              class={selectedLanguage === 'ar' && Styles.password_ar}
              error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
              icon_class={selectedLanguage === 'ar' && Styles.icon_ar}
              register={register('password_2')}
              inputClass={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
            />
          </div>
          <CenterRow position="relative" width={['100%', '100%', '100%', '50%']}>
            <TextField
              inputClass={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
              label={t('form_registration:fields:promoCode:label')}
              placeholder={t('form_registration:fields:promoCode:placeholder')}
              error={promoCodeError}
              error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
              register={register('promo_code')}
            />
            {isLoading && (
              <Box position="absolute" right={3} top={46}>
                <CircularProgress size={20} />
              </Box>
            )}
          </CenterRow>
          <CheckboxField
            class={`${Styles.check_box} ${selectedLanguage === 'ar' && Styles.check_box_ar}`}
            control={control}
            name={'is_agree_terms_conditions'}
            children={
              <div className={Styles.check_box__content}>
                {t('form_registration:check_boxes:terms_of_use:main')}
                <span onClick={(e) => handleClickLink(e, TERMS_OF_USE_LINK)}>{t('form_registration:check_boxes:terms_of_use:sub')}</span>
              </div>
            }
            error={errors.is_agree_terms_conditions}
            error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
            onCustomChange={(value) => setValue('is_agree_terms_conditions', value, { shouldValidate: true })}
            label={``}
          />
          <CheckboxField
            class={`${Styles.check_box} ${selectedLanguage === 'ar' && Styles.check_box_ar}`}
            control={control}
            name={'is_agree_privacy_policy'}
            onCustomChange={(value) => setValue('is_agree_privacy_policy', value, { shouldValidate: true })}
            error={errors?.is_agree_privacy_policy}
            error_wraper={selectedLanguage === 'ar' && Styles.error_ar}
            children={
              <div className={Styles.check_box__content}>
                {t('form_registration:check_boxes:privacy_policy:main')}
                <span onClick={(e) => handleClickLink(e, PRIVACY_POLICY_LINK)}>{t('form_registration:check_boxes:privacy_policy:sub')}</span>
              </div>
            }
          />
          <CheckboxField
            class={`${Styles.check_box} ${selectedLanguage === 'ar' && Styles.check_box_ar}`}
            control={control}
            name={'is_agree_to_receive_email'}
            onCustomChange={(value) => setValue('is_agree_to_receive_email', value)}
            children={<div className={Styles.check_box__content}>{t('form_registration:check_boxes:receive_email:main')}</div>}
          />
        </div>
        <div
          style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}
          onClick={() => {
            if (isVariantA) {
              setOnboardingStep(ONBOARDING_STEP.ADD_CHILD)
              setAddChildStep(1)
            } else {
              setOnboardingStep(ONBOARDING_STEP.REPORT)
            }
          }}
          className={Styles.main_container__back}
        >
          {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          {isVariantA ? t('add_child:footer:back') : t('form_registration:footer:back')}
        </div>
        <button
          disabled={!isValid || promoCodeError}
          onClick={handleSubmit(handleCreateAccount)}
          className={isValid && !promoCodeError && !loading ? Styles.main_container__btn : Styles.main_container__btn__disabled}
        >
          {t('form_registration:footer:next')}
        </button>
      </Box>
    </>
  )
}
