// Styles
import Styles from './Styles.module.scss'
// Utils
import { getInitials, returnCountry } from '../../../utils'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { CloseIcon, EyeVisible } from '../../../theme/assets/icons'
import { IconButton, Modal } from '@mui/material'
import { useState } from 'react'
import { Box, Flex, Container, Text } from 'components/Core'

export const GeneralProfileInformation = ({ profile, client, advisor }) => {
  const [reason, setReason] = useState('')
  const { open, onClose, onOpen } = useDisclosure()
  return (
    <Box bg="white" p={20} gap={16} height={'100%'}>
      <div className={Styles.main_container__header}>
        <div className={Styles.main_container__header__initials}>{getInitials(profile?.full_name)}</div>
        <h6>{profile?.full_name}</h6>
      </div>
      <Flex width="100%" gap="25px" flexDirection="column" pb={['24px']} borderBottom="1px solid" borderBottomColor="purple.200">
        <Flex gap="10px" flexDirection={['column', 'column', 'row']} width="100%" justifyContent={['space-between']}>
          <Flex gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Age:</Text>
            <Text variant="body5">{profile?.age}</Text>
          </Flex>
          <Flex gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Birthday:</Text>
            <Text variant="body5">{profile?.birth_date}</Text>
          </Flex>
        </Flex>
        <Flex flexWrap="wrap" gap={['10px']} flexDirection={['column', 'column', 'row']} justifyContent={['space-between']}>
          <Flex justifyContent={['space-between']} gap="16px">
            <Text variant="body6">Languages:</Text>
            <Text variant="body5">{profile?.languages?.join(', ')}</Text>
          </Flex>
          <Flex flexWrap={['wrap']} gap={['5px', '5px', '16px']} justifyContent={['space-between']}>
            <Text variant="body6">Problems:</Text>
            <Text variant="body5">{profile?.concerns?.join(', ')}</Text>
          </Flex>
        </Flex>
        <Flex gap="10px" flexDirection={['column', 'column', 'row']} width="100%" justifyContent={['space-between']}>
          <Flex flexWrap={['wrap']} gap={['5px', '5px', '16px']} justifyContent={['space-between']}>
            <Text variant="body6">Usual behavior:</Text>
            <Text variant="body5">{profile?.behavior?.join(', ')}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Box my={[25]}>
        <Flex gap="10px" flexDirection={['column', 'column', 'row']} width="100%" justifyContent={['space-between']}>
          <Flex gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Client name:</Text>
            <Text variant="body5">{client?.full_name}</Text>
          </Flex>
          <Flex gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Country:</Text>
            <Text variant="body5">{returnCountry(client?.country)}</Text>
          </Flex>
        </Flex>
      </Box>
      {profile?.assignedTherapists && (
        <Flex flexDirection={['column']}>
          <h6>Assigned Therapists Status</h6>
          <div style={{ gap: '5px', marginTop: 10 }} className={Styles.main_container__client_info}>
            {profile?.assignedTherapists?.map((therapist) => (
              <div className={Styles.main_container__client_info__container} key={therapist?.id}>
                <div>
                  <span>{therapist?.user?.full_name}</span>
                </div>
                <div>
                  <p>Therapist:</p>
                  <span style={{ textTransform: 'uppercase', color: statusColor[therapist?.approval_status?.therapist_status] }}>
                    {therapist?.approval_status?.therapist_status}
                  </span>
                  <ShowReason
                    onClick={() => {
                      setReason(therapist?.approval_status?.decline_reason)
                      onOpen()
                    }}
                    onOpen={onOpen}
                    status={therapist?.approval_status?.therapist_status}
                  />
                </div>
                <div>
                  <p>Parent:</p>
                  <span style={{ textTransform: 'uppercase', color: statusColor[therapist?.approval_status?.parent_status] }}>{therapist?.approval_status?.parent_status}</span>
                  <ShowReason
                    onClick={() => {
                      setReason(therapist?.approval_status?.decline_reason)
                      onOpen()
                    }}
                    onOpen={onOpen}
                    status={therapist?.approval_status?.parent_status}
                  />
                </div>
              </div>
            ))}
          </div>
        </Flex>
      )}
      {advisor && (
        <Flex mt={[2]} alignItems={['center']} gap="16px">
          <div className={Styles.main_container__advisor_info__initials}>{getInitials(advisor?.full_name)}</div>
          <div>
            <p>{advisor?.full_name}</p>
            <Text mt={[1]} variant="body6">
              Advisor
            </Text>
          </div>
        </Flex>
      )}
      <PopupDetail onClose={onClose} open={open} reason={reason} />
    </Box>
  )
}

const statusColor = {
  waiting: '#FFC107',
  accepted: '#4CAF50',
  declined: '#F44336',
}

const PopupDetail = ({ reason, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box bgcolor="white" p={3} width={['90%', '70%', '50%', '30%']} borderRadius={3}>
        <Box display="flex" mb={1} justifyContent="space-between" alignItems="center">
          <h6>Reason</h6>
          <IconButton>
            <CloseIcon className={Styles.header__icon} onClick={onClose} />
          </IconButton>
        </Box>
        {reason}
      </Box>
    </Modal>
  )
}

const ShowReason = ({ status, onClick }) => {
  return status === 'declined' ? (
    <IconButton onClick={onClick}>
      <EyeVisible />
    </IconButton>
  ) : null
}
