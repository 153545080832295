import type { ReactNode } from 'react'
import { CenterRow } from './Core'
import { theme } from 'utils/theme'
import { Skeleton, SkeletonProps } from '@mui/material'
type Props = {
  children: ReactNode
  onClick?: () => void
}
export const HourlyPriceCard = ({ children, onClick }: Props) => {
  return (
    <CenterRow
      _hover={{
        backgroundColor: theme.colors.typePurpure[200],
        color: 'white',
        borderColor: 'white',
      }}
      color="typePurpure.300"
      cursor="pointer"
      gap="10px"
      flexDirection="column"
      borderRadius={1}
      boxShadow={'base'}
      justifyContent="center"
      border={2}
      width={135}
      height={104}
      onClick={onClick}
    >
      {children}
    </CenterRow>
  )
}

interface SkeletonWrapperProps extends SkeletonProps {
  isLoading?: boolean
}
const SkeletonWrapper = ({ children, isLoading }: SkeletonWrapperProps) => {
  return isLoading ? (
    <Skeleton
      sx={{
        width: 135,
        height: 173,
      }}
    />
  ) : (
    children
  )
}
