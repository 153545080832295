export const errorsText = {
  user_not_found: `An account with this email already exist!`,
  'A user with this email already exists.': {
    en: `An account with this email already exist!`,
    ar: 'هناك حساب بهذا البريد الإلكتروني!',
  },
  'Passwords must match.': {
    en: 'Passwords must match.',
    ar: 'يجب أن تتطابق كلمات المرور',
  },
}
