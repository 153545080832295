import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { selectAlertState } from 'defaultLayout/slice/selectors'
import { AlertState } from 'defaultLayout/types'
import { useSelector } from 'react-redux'

export const useAlertController = () => {
  const alertState = useSelector(selectAlertState)

  const { actions, dispatch } = useDefaultLayoutSlice()

  const setAlert = (state: Partial<Omit<AlertState, 'open'>>) => {
    dispatch(actions.setAlert(state))
  }

  const closeAlert = () => {
    dispatch(actions.closeAlert())
  }

  return { ...alertState, setAlert, closeAlert }
}
