// Instruments
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

export const schemaValidEditTherapist = () => {
  return yupResolver(
    Yup.object().shape({
      session_price: Yup.string()
        .matches(/^[1-9]\d*$/, 'Price must be a positive integer')
        .required('Hourly price is required'),
      bio: Yup.string(),
      // .min(1, `This field can't be empty.`),
      expertise: Yup.array().of(Yup.object()).min(1, `This field can't be empty.`),
      clinical_services_provided: Yup.array().of(Yup.object()).min(1, `This field can't be empty.`),
      job_advantages: Yup.string().min(1, `This field can't be empty.`),
      experience: Yup.string()
        .min(1, `This field can't be empty.`)
        .max(20, 'This field must be at most 20 characters')
        .matches(/^\d{1,2}\+?$/, 'Experience must be a positive integer'),
      age_groups_served: Yup.string()
        .min(1, `This field can't be empty.`)
        .max(250, 'This field must be at most 250 characters')
        .matches(/^\d{1,2}(-\d{1,2})?\+?$/, 'Invalid data format. Examples: 10, 10-12, 10+, 10-12+.'),
    }),
  )
}
