// Core
import { element } from 'prop-types'
// Styles
import Styles from './styles.module.scss'
import { Box } from 'components/Core'

export const Authorization = ({ children }) => {
  return (
    <Box height={['auto', 'auto', '100vh', '100vh']} className={Styles.auth}>
      {children}
    </Box>
  )
}

Authorization.propTypes = {
  children: element,
}
