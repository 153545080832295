// Core
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Turnstile from 'react-turnstile'
// Routing
import { Link, useNavigate } from 'react-router-dom'
// Actions
import { authActions } from '../../../actions'
import { clearError } from '../../../reducers/generalSlice'
import { setUser } from '../../../reducers/authSlice'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextField, TextFieldPassword, Loader, SaveButton } from '../../GeneralComponents'
//Errors
import { Button } from 'components/Core'

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email address')
    .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, 'Please enter a valid email address, e.g. joe@mail.com'),
  password: Yup.string().required('Please enter password or login by authentication link'),
})

export const SignIn = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loading = useSelector((state) => state.general.loading)
  const error = useSelector((state) => state.general.error)
  const user = useSelector((state) => state.auth.user)

  const isLocalDevelopment = process.env.NODE_ENV === 'development'

  const [captcha, setCaptcha] = useState(isLocalDevelopment)

  const getCloudFlare = () => {
    return (
      <Turnstile
        sitekey={process.env.REACT_APP_SITE_KEY}
        onError={() => setCaptcha(false)}
        onVerify={(token) => token && setCaptcha(true)}
        theme={'light'}
        className={Styles.captcha}
      />
    )
  }

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email, { shouldValidate: true })
    }
  }, [user])

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const onFormSubmit = (data) => {
    Object.assign(data, { timeZone: userTimeZone })

    dispatch(authActions.loginUser(data))
  }

  const navigateToSignUp = () => {
    dispatch(clearError())
    navigate('/signup/client?lng=en')
  }

  const navigateToForgotPass = () => {
    const email = getValues('email')
    dispatch(clearError())
    dispatch(setUser({ email }))
    navigate('pass-recovery')
  }

  const handleAuthenticationAction = () => {
    const email = getValues('email')
    dispatch(setUser({ email }))
    dispatch(clearError())
    navigate('authentication-link')
  }

  return (
    <section className={Styles.signIn_form}>
      <div className={Styles.form_container}>
        {error && (
          <div className={Styles.signIn_error}>
            <span />
            <span>{error}</span>
          </div>
        )}
        <h4 className={Styles.signIn_caption}>{'Sign in'}</h4>
        <div className={Styles.signIn_link_block} style={{ marginBottom: 32, marginTop: 12 }}>
          <span className={Styles.link_text}>
            You can also sign in with{' '}
            <Button display="inline" type="button" variant="ghost" className={Styles.link} onClick={handleAuthenticationAction}>
              one-time authentication link
            </Button>
          </span>
        </div>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <TextField label={'Email'} requiredIcon={' *'} class={Styles.form_item} placeholder={'youremail@gmail.com'} error={errors?.email} register={register('email')} />
          <TextFieldPassword
            label={'Password'}
            requiredIcon={' *'}
            class={Styles.password_item}
            placeholder={'Enter your password'}
            error={errors?.password}
            register={register('password')}
          />
          <Button type="button" justifySelf="flex-end" variant="ghost" mb="10px" fontWeight="500" onClick={navigateToForgotPass}>
            Forgot password?
          </Button>
          {!isLocalDevelopment && getCloudFlare()}
          {loading ? <Loader /> : <SaveButton class={Styles.login_btn} isValid={isValid && captcha} title={'Log in'} />}
          <div className={Styles.clarification}>
            <span>{'Not a member?'}</span>
            <div className={Styles.account_btn} onClick={navigateToSignUp}>
              {'Create account'}
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}
