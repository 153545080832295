// Core
import { shape, string, bool, func, number } from 'prop-types'
import { useState } from 'react'

// Styles
import Styles from './styles.module.scss'
import StylesNewMessage from '../../MessagesHistory/NewMessageBar/styles.module.scss'

// Components
import { ReactComponent as CheckIcon } from '../../../../../theme/assets/icons/check_updated.svg'
import { ReactComponent as FileIcon } from '../../../../../theme/assets/icons/file_new.svg'
import { UserAvatar } from '../../UserAvatar'
import { Spinner } from '../../Spinner'

//Helpers
import { getFormatedLocalTime } from '../../../../../helpers'

//Utils
import { convertBytes, returnContent } from '../../../../../utils'

export const RecievedMessageInstance = ({ messageInfo, onChatDetailsOpenning, profileAvatar }) => {
  const { isViewed, content, contact, timestamp, attachment } = messageInfo
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenFile = async (url, filename) => {
    try {
      setIsLoading(true)

      const response = await fetch(url)
      const blob = await response.blob()
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = filename
      link.click()
      URL.revokeObjectURL(objectUrl)

      setIsLoading(false)
    } catch (error) {
      // console.error('Error downloading file:', error);
      setIsLoading(false)
    }
  }

  const getPreviewImg = (fileType, filePreview) => {
    switch (fileType) {
      case 'png':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      case 'jpg':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      default:
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <FileIcon />
          </div>
        )
    }
  }

  const thumbs = () => {
    return (
      <div className={StylesNewMessage.preview__item} key={attachment.name} onClick={() => handleOpenFile(attachment.url)}>
        <div className={StylesNewMessage.preview__item__preview}>
          <>
            {isLoading ? <Spinner /> : <>{getPreviewImg(attachment?.extension, attachment?.url)}</>}
            <div className={StylesNewMessage.preview__item__preview__description}>
              <p className={StylesNewMessage.preview__item__preview__description__name}>{attachment.filename}</p>
              <p className={StylesNewMessage.preview__item__preview__description__size}>{convertBytes(attachment.size)}</p>
            </div>
          </>
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.recievedMessageInstance}>
      <UserAvatar avatar={profileAvatar} online={contact?.online} onChatDetailsOpenning={onChatDetailsOpenning} />
      <div className={Styles.recievedMessageInstance__content}>
        <div className={Styles.recievedMessageInstance__content__message}>
          {attachment ? thumbs() : <p className={Styles.recievedMessageInstance__content__message__text}>{returnContent(content)}</p>}
          {/* {isViewed && (
            <div className={Styles.recievedMessageInstance__content__message__icon}>
              <CheckIcon />
            </div>
          )} */}
        </div>
        <div className={Styles.recievedMessageInstance__content__time}>{getFormatedLocalTime(timestamp)}</div>
      </div>
    </div>
  )
}
