import { Warning, WarningAmber } from '@mui/icons-material'
import { IconButton, Modal } from '@mui/material'
import { Button, CenterRow, Flex, Text } from 'components/Core'
import { Direction } from 'components/Core/common/types'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from 'theme/assets/icons'

type Props = {
  open: boolean
  onClose: () => void
  command?: () => void
  direction?: Direction
  /**
   * skipDiscoveryBooking:- is if user try to accept a therapist without a discovery call
   * skipDiscoveryCallMeeting:- is if user try to accept a therapist with a discovery call still on progress
   */
  variant: 'skipDiscoveryBooking' | 'skipDiscoveryCallMeeting'
}

export const DiscoveryCallSkipConfirmation = ({ direction, onClose, open, command, variant }: Props) => {
  const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose}>
      <Flex direction={direction} gap={20} flexDirection="column" variant="modal-container">
        <CenterRow justifyContent="space-between" width="100%">
          <Text variant="heading4">{t(`therapist_card:${variant}:title`)}</Text>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </CenterRow>
        <Text variant="body3" fontSize={20}>
          {t(`therapist_card:${variant}:description`)}
        </Text>
        <CenterRow justifyContent="flex-end" gap={20} width="100%">
          <Button
            onClick={() => {
              command?.()
              onClose()
            }}
            color="error"
            border={'1px solid #D42020'}
            variant="secondary"
          >
            {t(`therapist_card:${variant}:proceed`)}
          </Button>
          <Button onClick={onClose} variant="primary">
            {t(`therapist_card:${variant}:cancel`)}
          </Button>
        </CenterRow>
      </Flex>
    </Modal>
  )
}
