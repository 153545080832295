export const englishFeedbackContent = {
  give: 'Give feedback',
  submitted: 'Feedback submitted',
  good: 'Good',
  bad: 'Bad',
  title: 'How was your session?',
  submit: 'Submit',
  success: 'Thank you for your feedback!',
  goBack: 'Go Back',
  tellUsMore: 'Tell us more',
  optional: 'Optional',
  skipForNow: 'Skip for now',
  errors: {
    selectFeedback: 'Please first select feedback!',
    alreadyGaveFeedback: 'You already had given feedback!',
    failedToCreateFeedback: 'Failed to create feedback!',
    sessionNotFound: 'Session not found!',
    meetingIdIsRequired: 'Meeting ID is required!',
    unAuthorized: 'Unauthorized!',
  },
}
