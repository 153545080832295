import type { Direction } from 'components/Core/common/types'
import { useEffect } from 'react'
import { Button, Container, Text } from 'components/Core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import en from './locale/en.json'
import ar from './locale/ar.json'

export const Unauthorized = () => {
  const navigate = useNavigate()
  const language = localStorage.getItem('language')
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(language ?? 'en')
  }, [language])

  useEffect(() => {
    i18n.addResourceBundle('en', 'unauthorized', en)
    i18n.addResourceBundle('ar', 'unauthorized', ar)
  }, [])
  const direction = language === 'ar' ? 'rtl' : ('ltr' as Direction)
  return (
    <Container direction={direction} gap={20} flexDirection="column" height="100%">
      <Text textAlign="center" variant="heading4">
        {t('unauthorized:description')}
      </Text>
      <Button onClick={() => navigate('/')}>{t('unauthorized:goBack')}</Button>
    </Container>
  )
}
