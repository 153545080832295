//Styles
import Styles from './styles.module.scss'
//Core
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
//MUI
import { InputBase } from '@mui/material'
//Actions
import { advisorOnbordingActions, adminActions } from '../../../actions'
//Components
import { Table } from '../../Applies/Table'
import { SetApiModal } from '../../AdminPanel/AdvisorsTab/SetApiKey'
import { ConfirmationPopup, Notifications, NoDataComponent, CustomPagination } from '../../GeneralComponents'
import { EditTherapistInfoModal } from '../../AdminPanel/Popups/EditTherapistInfo'
import { TherapistInfoModal } from './TherapistsInfoModal'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { ParentsPopup } from '../../AdminPanel/Popups'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/check_updated.svg'
//Utils
import { paginateArray } from '../../../utils'

/**
 * AdvisorTherapists component for managing therapists data.
 * @returns {JSX.Element} AdvisorTherapists component JSX.
 */
export const AdvisorTherapists = () => {
  const dispatch = useDispatch()

  // State management
  /**
   * All therapists fetched from Redux store.
   * @type {Array}
   */
  const allTherapists = useSelector((state) => state.advisorOnbording.allTherapists)

  /**
   * User data fetched from Redux store.
   * @type {object}
   */
  const user = useSelector((state) => state.auth)

  /**
   * Loading state for indicating data fetching status.
   * @type {boolean}
   */
  const loading = useSelector((state) => state.general.loading)

  /**
   * State for managing visibility of API key modal.
   * @type {[boolean, function]}
   */
  const [isSetAPIKeyOpen, setIsSetAPIKeyOpen] = useState(false)

  /**
   * State for managing visibility of decline therapist confirmation popup.
   * @type {[boolean, function]}
   */
  const [isOpenDeclineTherapist, setIsOpenDeclineTherapist] = useState(false)

  /**
   * State for managing visibility of edit therapist info modal.
   * @type {[boolean, function]}
   */
  const [isOpenEditTherapist, setIsOpenEditTherapist] = useState(false)

  /**
   * State for managing visibility of therapist info modal.
   * @type {[boolean, function]}
   */
  const [isOpenTherapistInfo, setIsOpenTherapistInfo] = useState(false)

  /**
   * State for managing visibility of notification popup.
   * @type {[boolean, function]}
   */
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false)

  /**
   * State for managing visibility of parents popup.
   * @type {[boolean, function]}
   */
  const [isOpenParentsPopup, setIsOpenParentsPopup] = useState(false)

  /**
   * State for storing paginated therapist data.
   * @type {Array}
   */
  const [therapists, setTherapists] = useState([])

  /**
   * State for storing selected therapist ID.
   * @type {string | undefined}
   */
  const [selectedTherapistId, setSelectedTherapistId] = useState(undefined)

  /**
   * State for storing approved therapist data.
   * @type {object | undefined}
   */
  const [approvedTherapist, setApprovedTherapist] = useState(undefined)

  /**
   * State for storing selected therapist details.
   * @type {object | undefined}
   */
  const [selectedTherapist, setSelectedTherapist] = useState(undefined)

  /**
   * State for storing parents data associated with a therapist.
   * @type {Array}
   */
  const [parents, setParents] = useState([])

  /**
   * State for triggering data refetch.
   * @type {[boolean, function]}
   */
  const [refetch, setRefetch] = useState(false)

  /**
   * State for managing table headers.
   * @type {Array}
   */
  const [headers, setHeaders] = useState([])

  /**
   * State for managing current page in pagination.
   * @type {[number, function]}
   */
  const [currentPage, setCurrentPage] = useState(1)

  /**
   * Effect to fetch all therapists when user's token changes or refetch is triggered.
   */
  useEffect(() => {
    if (user.user.token) {
      dispatch(advisorOnbordingActions.getAllTherapists({ token: user.user.token }))
    }
  }, [refetch, user])

  /**
   * Effect to paginate therapists data whenever allTherapists or currentPage changes.
   */
  useEffect(() => {
    const paginatedData = paginateArray(allTherapists)
    if (paginatedData.length) {
      setTherapists([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setTherapists([])
    }
  }, [allTherapists, currentPage])

  /**
   * Opens the API key modal for a therapist.
   * @param {string} id - Therapist ID.
   */
  const handleOpenAPIKey = (id) => {
    setSelectedTherapistId(id)
    setIsSetAPIKeyOpen(true)
  }

  /**
   * Closes the API key modal and triggers data refetch.
   */
  const handleCloseAPIKey = () => {
    setIsSetAPIKeyOpen(false)
    setRefetch((prev) => !prev)
  }

  /**
   * Opens the decline therapist confirmation popup.
   * @param {string} therapistId - Therapist ID.
   */
  const handleOpenDeclineTherapistPopup = (therapistId) => {
    setIsOpenDeclineTherapist(true)
    setSelectedTherapistId(therapistId)
  }

  /**
   * Closes the decline therapist confirmation popup and triggers data refetch if status is 200.
   * @param {number} status - HTTP status code.
   */
  const handleCloseDeclineTherapistPopup = (status) => {
    setIsOpenDeclineTherapist(false)

    if (status === 200) {
      setRefetch((prev) => !prev)
    }
  }

  /**
   * Declines a therapist application.
   * @param {object} formData - Form data including reason for declining.
   */
  const declineTherapist = async (formData) => {
    const data = {
      token: user.user.token,
      id: selectedTherapistId,
      payload: {
        application_message: formData.reason,
      },
    }

    dispatch(adminActions.declineTherapist(data)).then((res) => handleCloseDeclineTherapistPopup(res.status))
  }

  /**
   * Approves a therapist application.
   * @param {string} therapistId - Therapist ID.
   */
  const approveTherapist = async (therapistId) => {
    const data = {
      token: user.user.token,
      id: therapistId,
    }

    dispatch(adminActions.approveTherapist(data)).then((res) => {
      setRefetch((prev) => !prev)
      openNotification()
    })
  }

  /**
   * Opens the edit therapist info modal.
   * @param {string} therapistId - Therapist ID.
   */
  const handleOpenEditTherapistPopup = (therapistId) => {
    setSelectedTherapistId(therapistId)
    setIsOpenEditTherapist(true)
  }

  /**
   * Closes the edit therapist info modal and triggers data refetch after a delay.
   */
  const handleCloseEditTherapistPopup = () => {
    setIsOpenEditTherapist(false)
    setTimeout(() => {
      setRefetch((prev) => !prev)
    }, 2000)
  }

  /**
   * Opens the therapist info modal.
   * @param {string} therapistId - Therapist ID.
   */
  const handleOpenTherapistInfo = (therapistId) => {
    setSelectedTherapistId(therapistId)
    setIsOpenTherapistInfo(true)
  }

  /**
   * Closes the therapist info modal.
   */
  const handleCloseTherapistInfo = () => setIsOpenTherapistInfo(false)

  /**
   * Opens the parents popup modal.
   * @param {Array} parents - List of parents associated with the therapist.
   * @param {object} therapist - Therapist object containing details like full name and email.
   */
  const handleOpenParentsPopup = (parents, therapist) => {
    const selectedTherapist = {
      full_name: therapist?.full_name,
      email: therapist?.email,
    }
    setParents(parents)
    setSelectedTherapist(selectedTherapist)
    setIsOpenParentsPopup(true)
  }

  /**
   * Closes the parents popup modal.
   */
  const handleCloseParentsPopup = () => {
    setIsOpenParentsPopup(false)
    setParents([])
    setSelectedTherapist(0)
  }

  /**
   * Sets table headers based on the user's role.
   */
  useEffect(() => {
    if (user?.user?.user?.user_type === 'admin') {
      setHeaders([
        { text: 'FULL NAME', sorting: true },
        { text: 'COUNTRY', sorting: true },
        { text: 'LANGUAGE', sorting: false },
        { text: 'YEARS EXPERIENCE', sorting: false },
        { text: 'QUALIFICATION', sorting: true },
        { text: 'PROFILES', sorting: false },
        { text: 'API KEY', sorting: false },
        // { text: 'Impersonate Therapist', sorting: false },
        { text: 'ACTION', sorting: false },
      ])
    } else {
      setHeaders([
        { text: 'FULL NAME', sorting: true },
        { text: 'COUNTRY', sorting: true },
        { text: 'LANGUAGE', sorting: false },
        { text: 'YEARS EXPERIENCE', sorting: false },
        { text: 'QUALIFICATION', sorting: true },
      ])
    }
  }, [user])

  /**
   * Content configuration for the decline therapist confirmation popup.
   * @type {object}
   */
  const contentForDeclineTherapistPopup = {
    title: 'Are you sure you want to decline the apply?',
    secondaryText: {
      text: 'What are reasons for declining?',
      style: Styles.popup_text,
    },
    buttons: {
      direction: 'vertical',
      content: [
        {
          id: 1,
          text: 'Continue',
          style: Styles.btn_decline_therapist__delete,
          onClick: declineTherapist,
        },
        {
          id: 2,
          text: 'Cancel',
          style: Styles.btn_decline_therapist__cancel,
          onClick: handleCloseDeclineTherapistPopup,
        },
      ],
    },
  }

  /**
   * Event handlers for various actions on therapist data.
   * @type {object}
   */
  const onClickEvents = {
    setApiKey: handleOpenAPIKey,
    acceptTherapist: approveTherapist,
    declineTherapist: handleOpenDeclineTherapistPopup,
    editProfile: handleOpenEditTherapistPopup,
    openUserDetails: handleOpenTherapistInfo,
    openParentsPopup: handleOpenParentsPopup,
  }

  /**
   * Handles input change for search functionality.
   * @param {Event} e - Input change event.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allTherapists.filter(
      (therapist) => therapist.user.full_name.toLowerCase().includes(inputValue) || therapist.user.email.toLowerCase().includes(inputValue),
    )

    const paginatedData = paginateArray(filteredArray)
    if (paginatedData.length) {
      setCurrentPage(1)
      setTherapists([...paginatedData[currentPage - 1]])
    } else {
      setTherapists([])
    }
  }

  /**
   * Closes the notification popup.
   */
  const closeNotification = () => setIsNotificationDisplayed(false)

  /**
   * Opens the notification popup.
   */
  const openNotification = () => setIsNotificationDisplayed(true)

  return (
    <div className={Styles.main_container}>
      {!loading && isNotificationDisplayed && (
        <Notifications
          icon={<TickIcon />}
          title={'Application accepted'}
          secondaryText={'You have accepted the profile of'}
          userName={`${approvedTherapist}.`}
          onClose={closeNotification}
        />
      )}
      <div className={Styles.header}>
        <div className={Styles.header__first_block}>
          <h6>Therapists</h6>
          <div className={Styles.header__search}>
            <div className={Styles.header__search__container}>
              <SearchIcon />
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name or email" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : allTherapists.length ? (
        <div className={Styles.data_container}>
          <Table
            headers={headers}
            data={therapists}
            refetch={setRefetch}
            userType={user?.user?.user?.user_type}
            userDataType={'therapist'}
            onClickEvents={onClickEvents}
            showNotification={setApprovedTherapist}
          />
        </div>
      ) : (
        <NoDataComponent text={'There are no therapists yet.'} />
      )}
      <CustomPagination dataArray={allTherapists} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {isSetAPIKeyOpen && <SetApiModal therapistId={selectedTherapistId} open={isSetAPIKeyOpen} onClose={handleCloseAPIKey} />}
      {isOpenDeclineTherapist && (
        <ConfirmationPopup
          open={isOpenDeclineTherapist}
          onClose={handleCloseDeclineTherapistPopup}
          content={contentForDeclineTherapistPopup}
          width={'436px'}
          isDeclinePopup={true}
        />
      )}
      {isOpenEditTherapist && <EditTherapistInfoModal onClose={handleCloseEditTherapistPopup} open={isOpenEditTherapist} selectedTherapistId={selectedTherapistId} />}
      {isOpenTherapistInfo && <TherapistInfoModal open={isOpenTherapistInfo} onClose={handleCloseTherapistInfo} therapistId={selectedTherapistId} />}
      {isOpenParentsPopup && <ParentsPopup open={isOpenParentsPopup} onClose={handleCloseParentsPopup} parents={parents} userType={'Therapist'} user={selectedTherapist} />}
    </div>
  )
}
