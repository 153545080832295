// Actions
import { setLoading, setError, clearError } from '../reducers/generalSlice'
import { setAllChannels, setContactDetails, setChatAttachments } from '../reducers/chatSlice'
// Api
import { chatApi } from '../api'

export const chatActions = {
  getAllChannels: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      chatApi
        .getAllChannels(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(setAllChannels(res))
            dispatch(clearError())
            dispatch(setLoading(false))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  getAllChannelsClient: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      chatApi
        .getAllChannelsClient(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(setAllChannels(res))
            dispatch(clearError())
            dispatch(setLoading(false))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  getContactDetails: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      chatApi
        .getContactDetails(data)
        .then((res) => {
          dispatch(setContactDetails(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('chatApi.getContactDetails', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('chatActions.getContactDetails', error)
      dispatch(setLoading(false))
    }
  },
  getChatAttachemnts: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      chatApi
        .getChatAttachemnts(data)
        .then((res) => {
          dispatch(setChatAttachments(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('chatApi.getChatAttachemnts', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('chatActions.getChatAttachemnts', error)
      dispatch(setLoading(false))
    }
  },
  viewedChat: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      chatApi
        .viewedChat(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            dispatch(clearError())
            dispatch(setLoading(false))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  archivedChat: (data) => (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      chatApi
        .archiveChat(data)
        .then((response) => {
          if (response.ok) {
            const { allChannels } = getState().chatSystem
            const updatedAllChannels = allChannels.filter((item) => item.id !== data.chatId)
            dispatch(setAllChannels(updatedAllChannels))
            dispatch(clearError())
            dispatch(setLoading(false))
          } else {
            dispatch(setLoading(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
}
