import { createSlice } from '@reduxjs/toolkit'
import type { CalComInitialState } from './types'

const initialState: CalComInitialState = {
  duration: 60,
}

export const calComSLice = createSlice({
  name: 'calCom',
  initialState,
  reducers: {
    setDuration: (state, action) => {
      state.duration = action.payload
    },
  },
})

export const calComActions = calComSLice.actions
export const calComeReducer = calComSLice.reducer
