//Styles
import Styles from './styles.module.scss'
//Utils
import { convertDate, convertTime, getMinutesBeforeEvent, checkIfDateIsTomorrowOrAfter, isSafariBrowser } from '../../../../utils'
//Icons
import { ReactComponent as UserIcon } from '../../../../theme/assets/icons/user.svg'
//Core
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
//Components
import { PaymentPage, MacOSPayment } from '../../../../pages/PaymentPages'
import { CountdownComponent, CustomTooltip } from '../../../GeneralComponents'
import { useDispatch } from 'react-redux'
import { setIsSelectedOpenZoomModal } from '../../../../reducers/zoomSlice'
import { SessionModalCreate } from '../../../TherapistFlow/Popups/SessionModalCreate'
import { SessionNoteModal } from '../SessionNoteModal'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { translateTherapistProfQualities, translateRecordStatuses } from '../../../../data/locale.data'
import { Box, Button, CenterRow } from 'components/Core'
import { useDisclosure } from 'hooks/useDisclosure'
import { FeedbackModal } from 'components/FeedbackModal/FeedbackModal'
import { useNavigate } from 'react-router-dom'
import { notify } from 'helpers'
import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { OpenSessionNotsWhen } from 'constants/index'

/**
 * HistoryRecord component displays details and actions for a specific session record.
 * @param {Object} props - Component props
 * @param {Object} props.record - The session record data
 * @param {string} props.userRole - The role of the user ('therapist' or 'parent')
 * @param {Function} props.openPaymentExpiresPopup - Function to open payment expiry popup
 * @param {string} props.clientName - Name of the client
 * @param {Object} props.parent - Parent object details
 */
export const HistoryRecord = ({ record, userRole, openPaymentExpiresPopup, clientName, parent }) => {
  const dispatch = useDispatch()
  const feedbackModal = useDisclosure()
  const { setZoomLeaveUrl } = useDefaultLayoutSlice()

  const { t } = useTranslation()

  const user = useSelector((state) => state.auth.user)
  const minutesBeforeCall = process.env.REACT_APP_MINUTES_BEFORE_CALL

  const [isOpenCreateNotePopup, setIsOpenCreateNotePopup] = useState(false)
  const [isOpenSeeNotePopup, setIsOpenSeeNotePopup] = useState(false)
  const [isOpenPaymentPopup, setIsOpenPaymentPopup] = useState(false)
  const [isOpenMacOSPaymentPopup, setIsOpenMacOSPaymentPopup] = useState(false)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'history_record', en)
    i18n.addResourceBundle('ar', 'history_record', ar)
  }, [])

  const [orderDetails, setOrderDetails] = useState({})

  const [paymentDetails, setPaymentDetails] = useState('')

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  /**
   * useEffect to fetch and set payment details from localStorage on component mount.
   */
  useEffect(() => {
    const paymentDetails = JSON.parse(localStorage.getItem(`payment_state_${record?.order?.order_id}`))
    setPaymentDetails(paymentDetails)
  }, [])

  /**
   * useEffect to handle removal of payment state from localStorage when session status changes.
   */
  useEffect(() => {
    if (user?.user?.user_type === 'parent') {
      if (record?.order?.transaction_id === paymentDetails?.transaction_id) {
        if (record?.calcom_session?.get_status !== 'Reserved') {
          localStorage.removeItem(`payment_state_${record?.order?.order_id}`)
        }
      }
    }
  }, [record])

  /**
   * Opens payment popup.
   */
  // const handleOpenPaymentPopup = () => setIsOpenPaymentPopup(true)
  const handleOpenPaymentPopup = () => setIsOpenPaymentPopup(true)

  /**
   * Closes payment popup.
   */
  const handleClosePaymentPopup = () => setIsOpenPaymentPopup(false)
  const navigate = useNavigate()
  /**
   * Handles button click actions based on session status.
   * @param {Object} record - The session record object
   */
  const handleClickBtn = (record) => {
    const meetingId = record.calcom_session.zoom_meeting_id
    const navigateToSession = () => {
      if (meetingId) {
        setZoomLeaveUrl()
        navigate('/session')
      } else {
        notify(t('alertMessages:zoomMeetingNotFound'), true, false)
      }
    }
    switch (record?.calcom_session?.get_status) {
      case 'Completed':
        break
      case 'Pending Payment':
        if (user?.user?.user_type) {
          if (paymentDetails?.transaction_id === record?.order?.transaction_id && paymentDetails?.status === '000') {
            return
          } else {
            setOrderDetails(record?.order)
            // if (isSafariBrowser()) {
            //   setIsOpenMacOSPaymentPopup(true)
            // } else {
            handleOpenPaymentPopup()
            // }
          }
        }
        break
      case 'Planned':
        dispatch(setIsSelectedOpenZoomModal(record.calcom_session.zoom_meeting_id))
        navigateToSession()
        break
      case 'In progress':
        dispatch(setIsSelectedOpenZoomModal(record.calcom_session.zoom_meeting_id))
        navigateToSession()
        break
      case 'Canceled - Payment Canceled':
        break
      default:
        break
    }
  }

  /**
   * Returns status container based on the session status.
   * @param {string} status - The status of the session
   * @returns {JSX.Element} - Status container component
   */
  const returnStatus = (status) => {
    let style
    switch (status) {
      case 'Completed':
        style = { background: '#F6F0FC', color: '#8450a0' }
        break
      case 'Planned':
        style = { background: '#F8FFEB', color: '#3B8400' }
        break
      case 'Pending Payment':
        style = { background: '#FFFBE4', color: '#B19607' }
        break
      case 'In progress':
        style = { background: '#FFFBE4', color: '#B19607' }
        break
      case 'Canceled - Payment Canceled':
        style = { background: '#FFE4E4', color: '#E51616' }
        break
      default:
        style = { background: '#FFE4E4', color: '#E51616' }
        break
    }

    return (
      <div className={Styles.status_container} style={style}>
        {translateRecordStatuses?.[status]?.[selectedLanguage]?.toUpperCase()}
      </div>
    )
  }

  /**
   * Returns actions for therapist based on the session status.
   * @param {string} status - The status of the session
   * @returns {JSX.Element} - Actions component for therapist
   */
  const returnActionsForTherapist = (status) => {
    let btnType
    let btnText

    switch (status) {
      case 'Completed':
        break
      case 'Planned':
        btnType = 'violet'
        btnText = t('history_record:btns:join')
        break
      case 'In progress':
        btnType = 'violet'
        btnText = t('history_record:btns:join')
        break
      case 'Pending Payment':
        break
      case 'Canceled - Payment Canceled':
        break
      default:
        break
    }

    const minutesBeforeEvent = getMinutesBeforeEvent(record?.calcom_session?.start_time)
    const isDateTomorrowOrAfter = checkIfDateIsTomorrowOrAfter(record?.calcom_session?.start_time)

    return (
      btnType && (
        <>
          {(status === 'Planned' || status === 'In progress') && (
            <CustomTooltip
              isOpen={isTooltipOpen}
              setIsOpen={setIsTooltipOpen}
              title={isDateTomorrowOrAfter ? `${t('history_record:tooltip')}` : ''}
              placement="top"
              titleFontSize="14px"
            >
              <Button
                disabled={isDateTomorrowOrAfter}
                onClick={() => {
                  handleClickBtn(record)
                }}
              >
                {btnText}
              </Button>
            </CustomTooltip>
          )}
        </>
      )
    )
  }

  /**
   * Returns actions for parent based on the session status.
   * @param {string} status - The status of the session
   * @param {Object} record - The session record object
   * @returns {JSX.Element} - Actions component for parent
   */
  const returnActionsForParent = () => {
    const status = record?.calcom_session?.get_status
    let btnType
    let btnText
    let isDisabled
    let isPayment

    switch (status) {
      case 'Completed':
        break
      case 'Planned':
        btnType = 'violet'
        btnText = t('history_record:btns:join')
        break
      case 'In progress':
        btnType = 'violet'
        btnText = t('history_record:btns:join')
        break
      case 'Pending Payment':
        btnType = 'green'
        btnText = t('history_record:btns:pay')
        isPayment = true

        if (paymentDetails?.transaction_id === record?.order?.transaction_id && paymentDetails?.status === '000') {
          isDisabled = true
        }

        break
      case 'Canceled - Payment Canceled':
        break
      default:
        break
    }

    const isDateTomorrowOrAfter = checkIfDateIsTomorrowOrAfter(record?.calcom_session?.start_time)

    return (
      btnType && (
        <>
          {isDateTomorrowOrAfter && (status === 'Planned' || status === 'In progress') && (
            <CustomTooltip isOpen={isTooltipOpen} setIsOpen={setIsTooltipOpen} title={`${t('history_record:tooltip')}`} placement="top" titleFontSize="14px">
              <Button onClick={() => setIsTooltipOpen(true)} disabled={isDateTomorrowOrAfter}>
                {btnText}
              </Button>
            </CustomTooltip>
          )}
          {(!isDateTomorrowOrAfter || status === 'Pending Payment') && (
            <div onClick={() => handleClickBtn(record)} className={false ? `${Styles[`${btnType}_btn_disabled`]}` : `${Styles[`${btnType}_btn`]}`}>
              {btnText}
              {isPayment && !isDisabled && <CountdownComponent creationTimestamp={record?.order?.created_at} />}
            </div>
          )}
        </>
      )
    )
  }

  /**
   * Opens session note creation popup.
   */
  const handleOpenSessionNoteCreation = () => {
    setIsOpenCreateNotePopup(true)
  }

  /**
   * Closes session note creation popup.
   */
  const handleCloseSessionMoteCreation = () => {
    setIsOpenCreateNotePopup(false)
  }

  /**
   * Opens session note popup.
   * @param {Object} note - The session note object
   */
  const handleOpenSessionNote = (note) => {
    if (user?.user?.user_type === 'parent') {
      setIsOpenSeeNotePopup(true)
      return
    }

    if (note?.status === 'submitted' || note?.status === 'approved') {
      setIsOpenSeeNotePopup(true)
    } else {
      setIsOpenCreateNotePopup(true)
    }
  }

  /**
   * Closes session note popup.
   */
  const handleCloseSessionNote = () => {
    setIsOpenSeeNotePopup(false)
  }
  return (
    <div className={Styles.main_container}>
      <div className={Styles.header}>
        <div className={Styles.header__record_info}>
          <span>{t('history_record:date')}</span>
          <div className={Styles.date_and_time}>
            <span className={Styles.date}>{convertDate(record?.calcom_session?.start_time)}</span>
            <span className={Styles.time}>
              {record?.calcom_session?.end_time
                ? `${convertTime(record?.calcom_session?.start_time)} - ${convertTime(record?.calcom_session?.end_time)}`
                : `${convertTime(record?.calcom_session?.start_time)}`}
            </span>
          </div>
        </div>
        <div style={{ height: 'fit-content' }}>{returnStatus(record?.calcom_session?.get_status)}</div>
      </div>
      <Box width="fit-content" className={Styles.session_note}>
        {userRole === 'therapist' && OpenSessionNotsWhen.includes(record?.calcom_session?.get_status) && !record?.meeting_notes && (
          <p onClick={handleOpenSessionNoteCreation}>{t('history_record:session_note:create')}</p>
        )}
        {userRole === 'therapist' && OpenSessionNotsWhen.includes(record?.calcom_session?.get_status) && record?.meeting_notes && (
          <p onClick={() => handleOpenSessionNote(record?.meeting_notes)}>{t('history_record:session_note:open')}</p>
        )}
      </Box>
      <div className={Styles.session_note}>
        {userRole === 'parent' &&
          record?.therapist &&
          (record?.calcom_session?.get_status === 'In progress' || record?.calcom_session?.get_status === 'Planned' || record?.calcom_session?.get_status === 'Completed') &&
          record?.meeting_notes && <p onClick={() => handleOpenSessionNote(record?.meeting_notes)}>{t('history_record:session_note:open')}</p>}
      </div>
      <div className={Styles.content}>
        {userRole === 'parent' || userRole === 'admin' ? (
          <CenterRow width="100%" justifyContent="space-between" className={Styles.therapist_info}>
            <CenterRow gap={16}>
              <div className={Styles.therapist_info__photo} style={{ background: record?.therapist?.profile_pic ? 'none' : '#EEE9F3' }}>
                {record?.organizer?.profile_pic ? (
                  <img src={record?.organizer?.profile_pic} alt="photo" />
                ) : record?.therapist?.profile_pic ? (
                  <img src={record.therapist.profile_pic} alt="photo" />
                ) : (
                  <UserIcon />
                )}
              </div>
              <div className={`${Styles.therapist_info__content} ClarityMask`}>
                <p className={Styles.name}>{record?.therapist?.full_name || record?.organizer?.full_name}</p>
                <span className={Styles.occupation}>
                  {record?.therapist?.professional_qualities?.map((item) => translateTherapistProfQualities?.[item]?.[selectedLanguage] || item)?.join(', ') ||
                    t('history_record:advisor')}
                </span>
              </div>
            </CenterRow>
            {record?.calcom_session?.get_status === 'Completed' && !record?.calcom_session?.feedback && (
              <Button onClick={feedbackModal.onOpen} variant="ghost">
                {t('history_record:feedback:give')}
              </Button>
            )}
          </CenterRow>
        ) : (
          record?.calcom_session?.get_status === 'Completed' &&
          !record?.calcom_session?.feedback &&
          userRole === 'therapist' && (
            <CenterRow width="100%" justifyContent="flex-end">
              <Button onClick={feedbackModal.onOpen} variant="ghost">
                {t('history_record:feedback:give')}
              </Button>
            </CenterRow>
          )
        )}
        {userRole === 'parent' && returnActionsForParent()}
        {userRole !== 'parent' && returnActionsForTherapist(record?.calcom_session?.get_status)}
      </div>
      {isOpenPaymentPopup && (
        <PaymentPage isOpen={isOpenPaymentPopup} closePopup={handleClosePaymentPopup} orderDetails={orderDetails} openPaymentExpiresPopup={openPaymentExpiresPopup} />
      )}
      {isOpenMacOSPaymentPopup && (
        <MacOSPayment
          open={isOpenMacOSPaymentPopup}
          onClose={() => setIsOpenMacOSPaymentPopup(false)}
          orderDetails={orderDetails}
          openPaymentExpiresPopup={openPaymentExpiresPopup}
        />
      )}
      {isOpenCreateNotePopup && (
        <SessionModalCreate
          open={isOpenCreateNotePopup}
          onClose={handleCloseSessionMoteCreation}
          record={record}
          clientName={clientName}
          parent={parent}
          openSessionModal={setIsOpenSeeNotePopup}
        />
      )}
      {isOpenSeeNotePopup && (
        <SessionNoteModal parent={parent} open={isOpenSeeNotePopup} onClose={handleCloseSessionNote} record={record} clientName={record?.therapist?.full_name || clientName} />
      )}
      <FeedbackModal {...feedbackModal} record={record} userRole={userRole} />
    </div>
  )
}
