// Core
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
// Routing
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import history from './lib/history'
// Localization
import './i18n'
// Instruments
import './theme/main.scss'
import 'react-toastify/dist/ReactToastify.css'
import { clarity } from 'react-microsoft-clarity'
// Store
import store from './store'
// Components
import App from './App'
// Context
import { AuthProvider } from './context/AuthContext'
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components'
import { ThemeProvider } from '@mui/material'
import { muiTheme } from './utils/muiThem'
import { theme } from './utils/theme'
import './index.css'

import { configure } from 'mobx'
import { DefaultLayout } from 'defaultLayout'

configure({ isolateGlobalState: true })

const root = createRoot(document.getElementById('root'))

if (process.env.NODE_ENV !== 'development') {
  clarity.init(process.env.REACT_APP_CLARITY_ID)
  clarity.consent()
}

root.render(
  <StyledComponentThemeProvider theme={theme}>
    <ThemeProvider theme={muiTheme}>
      <Provider store={store}>
        <HistoryRouter history={history}>
          <App />
          <DefaultLayout />
        </HistoryRouter>
      </Provider>
    </ThemeProvider>
  </StyledComponentThemeProvider>,
)
