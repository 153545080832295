import { apiRoutes } from '../../../../apiRoutes'
import { rootServiceApi } from '../../../../store/service'

export const therapistServices = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    createTherapistAccount: build.mutation({
      query: ({ therapistInfo, documents }) => {
        const formData = new FormData()
        formData.append('therapistInfo', JSON.stringify(therapistInfo))

        // Group documents by type and append each file with its type
        // The [] will tell the backend to treat the file as an array
        documents.forEach((document) => {
          formData.append(`${document.type}[]`, document.file)
        })

        return {
          url: apiRoutes.therapist.create,
          body: formData,
          method: 'POST',
          formData: true,
        }
      },
    }),
  }),
})

export const { useCreateTherapistAccountMutation } = therapistServices
