//Core
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//MUI
import { Modal, ToggleButtonGroup, ToggleButton, IconButton } from '@mui/material'
//Images
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
//Components
import { CalComIntegration } from '../../../GeneralComponents'
import { useTranslation } from 'react-i18next'
import english from './locale/en.json'
import arabic from './locale/ar.json'
import { Box, CenterRow } from 'components/Core'
import { useGetLanguage } from 'hooks/useGetLanguage'

const AddProfilePopup = ({ open, onClose, isAdultHasProfile }) => {
  const language = localStorage.getItem('language')
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.user)
  const [calComLink, setCalComLink] = useState('')

  const [profileOption, setProfileOption] = useState('')
  const [isOpenCalcomPopup, setIsOpenCalcomPopup] = useState(false)
  const [searchParams] = useSearchParams()

  const handleOpenAddProfileCalcomPopup = useCallback(() => {
    if (profileOption === 'adult') {
      setIsOpenCalcomPopup(true)
      setCalComLink(process.env.REACT_APP_CAL_COM_LINK_PARENT)
    }

    if (profileOption === 'child') {
      navigate(`add_questionnaire?lng=${searchParams.get('lng') ?? localStorage.getItem('language') ?? 'en'}`)
      onClose()
    }
  }, [profileOption, searchParams.get('lng')])

  const handleCloseAddProfileCalcomPopup = () => {
    setIsOpenCalcomPopup(false)
    onClose()
  }

  const handleChange = (_, newProfileOption) => {
    setProfileOption(newProfileOption)
  }
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.addResourceBundle('en', 'addProfilePopup', english)
    i18n.addResourceBundle('ar', 'addProfilePopup', arabic)
  }, [])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const { direction } = useGetLanguage({ userType: 'parent' })

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={Styles.profile_container}>
        <CenterRow direction={direction} justifyContent="space-between">
          <h2 className={Styles.profile_title}>{t('addProfilePopup:newProfile')}</h2>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </CenterRow>
        <p className={Styles.profile_question}>{t('addProfilePopup:createProfileFor')}</p>
        <div className={Styles.profile_buttons}>
          <ToggleButtonGroup color="secondary" exclusive value={profileOption} onChange={handleChange}>
            <ToggleButton disabled={isAdultHasProfile} value="adult">
              {t('addProfilePopup:myself')}
            </ToggleButton>
            <ToggleButton value="child">{t('addProfilePopup:child')}</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={Styles.profile_next_block}>
          <div onClick={handleOpenAddProfileCalcomPopup} className={Styles.profile_next}>
            {t('addProfilePopup:next')}
          </div>
        </div>
        {isOpenCalcomPopup && (
          <CalComIntegration userInfo={user?.user ?? user} open={isOpenCalcomPopup} onClose={handleCloseAddProfileCalcomPopup} link={calComLink} isRegularCall={false} />
        )}
      </Box>
    </Modal>
  )
}

export default AddProfilePopup
