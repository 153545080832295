import styled from 'styled-components'
import { fontSize, fontWeight, textAlign, variant } from 'styled-system'
import type { TextProps } from './types'
import { commonComposes } from '../common'
import { theme } from 'utils/theme'

export const Text = styled('p')<TextProps>(
  {
    fontFamily: 'Poppins, sans-serif',
  },
  variant({
    variants: {
      body1: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Poppins, sans-serif',
      },
      body2: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Poppins, sans-serif',
      },
      body3: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Poppins, sans-serif',
      },
      body4: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Poppins, sans-serif',
      },
      body5: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Poppins, sans-serif',
      },
      body6: {
        fontWeight: 500,
        fontSize: '14px',
        fontFamily: 'Poppins, sans-serif',
        color: 'textGray',
      },
      heading1: {
        color: theme.colors.black[900],
        fontSize: ['26px', '26px', '30px', '30px', '36px', '36px'],
        fontWeight: 600,
        lineHeight: '["normal", "normal", "normal", "40px", "36px", "40px", "46px"]',
        maxWidth: '580px',
        width: '100%',
      },
      heading2: {
        color: theme.colors.black[900],
        fontSize: ['20px', '20px', '25px', '25px', '26px', '28px', '30px'],
        fontWeight: 600,
        lineHeight: ['normal'],
        maxWidth: '580px',
        width: '100%',
      },
      heading3: {
        color: theme.colors.black[900],
        fontSize: ['20px', '20px', '23px', '23px', '23px', '26px', '27px'],
        fontWeight: 600,
        lineHeight: ['normal'],
        maxWidth: '580px',
        width: '100%',
      },
      heading4: {
        color: theme.colors.black[900],
        fontSize: ['17px', '17px', '19px', '19px', '20px', '23px', '23px'],
        fontWeight: 600,
        lineHeight: ['normal'],
        maxWidth: '580px',
        width: '100%',
      },

      numberHolder: {
        color: 'white',
        backgroundColor: 'typePurpure.200',
        fontWeight: 600,
        fontSize: '14px',
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 0,
      },
      error: {
        color: theme.colors.error,
        fontSize: '12px',
      },
    },
  }),
  commonComposes([textAlign, fontWeight, fontSize]),
)
