export const returnParagraphForReport = (childName) => {
  return {
    en: (
      <>
        Based on your responses, it appears that <span className={'ClarityMask'}>{childName}</span> might benefit from support in the following areas
      </>
    ),
    ar: `بناءً على إجاباتك، يبدو أن طفلك قد يستفيد من الدعم في المجالات التالية:`,
  }
}

export const returnParagraphForNoReport = (childName) => {
  return {
    en: `Based on your responses, it doesn't seem like you have any specific concerns about ${childName ?? 'Your Child'}'s development or behavior at this time.`,
    ar: `بناءً على إجاباتك، لا يبدو أنك لديك أية مخاوف محددة بشأن تطور طفلك أو سلوكياته في هذا الوقت.`,
  }
}
