// Actions
import { setLoading, clearError } from '../reducers/generalSlice'
import { setStatus } from '../reducers/verificationSlice'
import { setUser, setIsAuth } from '../reducers/authSlice'
// Api
import { verificationApi } from '../api'
// Routing
import history from '../lib/history'
// Utils
import { setCookie } from '../utils'
import { notify } from '../helpers'

export const verificationActions = {
  createVerificationLink: (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true))
      verificationApi
        .createVerificationLink(data)
        .then((res) => {
          dispatch(setLoading(false))
          dispatch(clearError())
          resolve(res)
        })
        .catch((error) => {
          // console.log('verificationApi.createVerificationLink', error)
          if (error?.response?.status === 403) {
            dispatch(setUser({ email: data?.payload?.email }))
            history.push('/signup/verify-email')
            dispatch(setLoading(false))
            reject(error)
          }

          notify(error?.response?.data?.message)
          dispatch(setLoading(false))
          reject(error)
        })
    })
  },
  verifyLink: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      verificationApi
        .verifyToken(data)
        .then((response) => {
          localStorage.setItem('token', response?.data?.token)
          dispatch(setStatus(200))
          dispatch(setUser(response.data))
          dispatch(setIsAuth(true))
          dispatch(setLoading(false))

          const res = response.data

          const user = {
            user: {
              id: res.user.id,
              user_type: res.user.user_type,
              is_email_confirmed: res.user.is_email_confirmed,
              timeZone: data.timeZone,
            },
            profile: res.profile,
            token: res.token,
          }
          setCookie('user', JSON.stringify(user), {
            secure: true,
            samesite: true,
            'max-age': 43200,
          })

          return res
        })
        .then((res) => {
          if (!res) {
            return null
          }
          if (res.user.user_type === 'parent') {
            history.push(`/profile/${res.user.user_type}/therapy`)
          }

          if (res.user.user_type === 'therapist') {
            history.push(`/profile/${res.user.user_type}/applications`)
          }

          if (res.user.user_type === 'advisor' && !data?.isSignup) {
            history.push(`/admin_panel/${res.user.user_type}/applications`)
          }

          if (res.user.user_type === 'admin') {
            history.push(`/admin_panel/${res.user.user_type}/advisors`)
          }
        })
        .catch((error) => {
          // console.log('verificationApi.verifyLink', error)
          dispatch(setStatus(error.response.status))
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('verificationActions.verifyLink', error)
      dispatch(setLoading(false))
    }
  },
}
