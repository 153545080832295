//Core
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
//Styles
import Styles from './Styles.module.scss'
//Form
import { useForm } from 'react-hook-form'
import { schemaValid } from './validation'
//Components
import { DatePickerField, TextField, TextFieldMultiple } from '../../../GeneralComponents'
// Data
import { languages, parent_onboard_child_behavior, parent_onboard_child_concerns } from '../../../../data'
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME } from '../../../../data/enums'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
//Utils
import dayjs from 'dayjs'
import { getLocalStorage, setLocalStorage } from '../../../../utils'
// Icons
import { ReactComponent as TickIcon } from '../../../../theme/assets/icons/check_white.svg'
// i18n
import { useTranslation } from 'react-i18next'
import ar from './locale.js/ar.json'
import en from './locale.js/en.json'
import { returnBehavior } from './locale.js/locale.functions'
import { englishValidationErrors } from '../../../../locales/validations/englishValiationErrors'
import { arabicValidationErrors } from '../../../../locales/validations/arabicValidationErrors'
import { Button, Container, Text } from 'components/Core'
import { useGetLanguage } from 'hooks/useGetLanguage'

export const ParentOnboardingAddChild = ({ setOnboardingStep, isAccountExists, addChildStep, setAddChildStep, isVariantA, isVariantB, navigateToParentForm }) => {
  const navigate = useNavigate()

  const [valuesForConcerns, setValuesForConcerns] = useState([])
  const [formIsValid, setFormIsValid] = useState(false)
  const [valuesForLanguages, setValuesForLanguages] = useState([])
  const [valuesForBehavior, setValuesForBehavior] = useState([])

  const [childName, setChildName] = useState(null)

  const today = dayjs()
  const maximumDate = today.add(-1, 'year')
  const minimumDate = today.add(-18, 'year')

  const { t, i18n } = useTranslation()

  const { language } = useGetLanguage({ userType: 'parent' })

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    i18n.addResourceBundle('en', 'add_child', en)
    i18n.addResourceBundle('ar', 'add_child', ar)
    i18n.addResourceBundle('en', 'validation', englishValidationErrors)
    i18n.addResourceBundle('ar', 'validation', arabicValidationErrors)
  }, [])

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: schemaValid(t),
    defaultValues: {
      child_name: '',
      birth_date: '',
      concerns: [],
      languages: [],
    },
  })
  useEffect(() => {
    const childInfo = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))

    setValue('child_name', childInfo?.child_info?.child_name || '')
    setValue('birth_date', childInfo?.child_info?.birth_date || '')
    setValue('concerns', childInfo?.child_info?.concerns || [])
    setValue('languages', childInfo?.child_info?.languages || [])
    setValuesForConcerns(childInfo?.child_info?.concerns || [])
    setValuesForLanguages(childInfo?.child_info?.languages?.map((i) => ({ value: i })) || [])
    setValuesForBehavior(childInfo?.child_info?.behavior || [])

    if (childInfo?.child_info?.child_name && childInfo?.child_info?.birth_date && childInfo?.child_info?.concerns?.length > 0 && childInfo?.child_info?.languages?.length > 0) {
      setFormIsValid(true)
    }

    if (childInfo?.child_info?.child_name && isVariantB) {
      setChildName(childInfo?.child_info?.child_name)
    }
  }, [isVariantB])

  const handleSubmitChildInformation = (data) => {
    const localStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING) ?? '{}')

    if (addChildStep === 1) {
      const dataForSave = {
        ...localStorage.child_info,
        ...data,
        languages: data?.languages?.map((i) => i?.value || i),
      }

      localStorage.child_info = dataForSave
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(localStorage))

      if (isVariantA && (isValid || formIsValid)) {
        navigateToParentForm()
      } else {
        setAddChildStep(2)
      }
    } else {
      const dataForSave = {
        ...data,
        languages: data?.languages?.map((i) => i?.value || i),
        behavior: valuesForBehavior,
      }

      localStorage.child_info = dataForSave
      localStorage.state = `${ONBOARDING_STEP.QUESTIONNAIRE}`
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(localStorage))
      setOnboardingStep(`${ONBOARDING_STEP.QUESTIONNAIRE}`)
    }
    // If form is valid and it is variant A, navigate to parent form
  }

  const handleSelectItem = (item) => {
    const localStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    let finalArray

    if (valuesForBehavior?.some((i) => i?.value === item?.value)) {
      finalArray = valuesForBehavior?.filter((i) => i?.value !== item?.value)
      setValuesForBehavior(finalArray)
    } else {
      finalArray = [...valuesForBehavior, item]
      setValuesForBehavior(finalArray)
    }

    localStorage.child_info.behavior = finalArray
    setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(localStorage))
  }

  const handleCheckIsValid = () => {
    if (addChildStep === 1 && isValid) {
      return true
    } else if (addChildStep === 2 && valuesForBehavior?.length) {
      return true
    } else {
      return false
    }
  }

  const handleGoBack = () => {
    if (isAccountExists) {
      navigate('/profile/parent/therapy')
    } else {
      if (isVariantA) {
        setOnboardingStep(ONBOARDING_STEP.REPORT)
        setAddChildStep(2)
      } else {
        setAddChildStep(1)
      }
    }
  }

  const error_wraper = useMemo(() => {
    return language === 'ar' && Styles.text_field_ar
  }, [language])

  const disableGoBack = useMemo(() => {
    if (isVariantA && addChildStep === 2) {
      return true
    }
    if (isVariantA && addChildStep === 1) {
      return false
    }
    if (isVariantB && addChildStep === 1) {
      return true
    }
  }, [isVariantA, addChildStep, isVariantB])
  const disableNext = useMemo(() => {
    if (formIsValid && Object.keys(errors).length === 0) {
      if (valuesForBehavior.length === 0 && addChildStep === 2) {
        return true
      }
      return false
    }
    if (isVariantA) {
      if (addChildStep === 2) {
        return valuesForBehavior.length === 0
      } else if (addChildStep === 1 && isValid) {
        return false
      } else {
        return true
      }
    } else if (isVariantB) {
      if (addChildStep === 1) {
        // If not valid disable next button (Opposite)
        return !isValid
      } else if (addChildStep === 2) {
        return valuesForBehavior.length === 0
      }
    }
  }, [isVariantB, isValid, isVariantA, addChildStep, valuesForBehavior, errors, formIsValid])
  const { direction } = useGetLanguage()

  const variantANextHandler = () => {
    const localStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING) ?? '{}')

    const dataForSave = {
      ...(localStorage ?? {}),
      child_info: {
        ...(localStorage ?? {})?.child_info,
        behavior: [],
      },
      state: `${ONBOARDING_STEP.QUESTIONNAIRE}`,
    }

    setValuesForBehavior([])
    setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(dataForSave))
    setOnboardingStep(`${ONBOARDING_STEP.QUESTIONNAIRE}`)
  }

  return (
    <Container height={['auto', 'auto', '100%']} py={20} textAlign={language === 'en' ? 'left' : 'right'}>
      <div className={Styles.main_container}>
        <div className={Styles.main_container__header}>
          {addChildStep === 1 ? (
            <>
              {isVariantB ? <h4>{t('add_child:welcome')}</h4> : null}
              <p style={{ fontSize: 20 }}>{isVariantA ? t('add_child:childInfoHeader') : t('add_child:provide_support')}</p>
            </>
          ) : (
            isVariantB && <p className={'ClarityMask'}>{returnBehavior(childName)?.[language]}</p>
          )}
        </div>
        {addChildStep === 1 && (
          <div className={Styles.main_container__form}>
            <TextField
              label={t('add_child:fields:child_name:label')}
              placeholder={t('add_child:fields:child_name:placeholder')}
              error={errors?.child_name}
              register={register('child_name')}
              inputClass={language === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
              error_wraper={error_wraper}
            />
            <DatePickerField
              error={errors?.birth_date}
              name={'birth_date'}
              control={control}
              label={t('add_child:fields:birth_date:label')}
              maximumDate={maximumDate}
              minimumDate={minimumDate}
              class={language === 'en' ? Styles.date_picker_en : Styles?.date_picker_ar}
              error_wraper={error_wraper}
            />
            <div className={Styles.main_container__form__selector}>
              <TextFieldMultiple
                name="concerns"
                value={valuesForConcerns}
                setNewValue={setValuesForConcerns}
                control={control}
                options={parent_onboard_child_concerns}
                setValue={setValue}
                freeSolo={true}
                disabled={false}
                language={language}
                error={errors?.concerns}
                FormHelperTextProps={{
                  style: errors?.concerns ? { textAlign: 'right' } : {},
                }}
                class_field={language === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
                label={t('add_child:fields:concerns:label')}
                placeholder={t('add_child:fields:concerns:placeholder')}
                hintMessage={t('add_child:fields:concerns:hint')}
                error_wraper={error_wraper}
              />
            </div>
            <div className={Styles.main_container__form__selector}>
              <TextFieldMultiple
                name="languages"
                value={valuesForLanguages}
                setNewValue={setValuesForLanguages}
                control={control}
                options={languages}
                setValue={setValue}
                freeSolo={true}
                disabled={false}
                error={errors?.languages}
                label={t('add_child:fields:languages:label')}
                placeholder={t('add_child:fields:languages:placeholder')}
                hintMessage={t('add_child:fields:languages:hint')}
                register={register('languages')}
                error_wraper={error_wraper}
                disableNewValue
                class_field={language === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
              />
            </div>
          </div>
        )}
        {addChildStep === 2 &&
          (isVariantB ? (
            <div className={Styles.main_container__behavior_container}>
              {parent_onboard_child_behavior?.map((item, index) => (
                <div
                  style={{ flexDirection: language === 'en' ? 'row' : 'row-reverse' }}
                  className={
                    valuesForBehavior?.some((i) => i?.value === item?.value) ? Styles.main_container__behavior_container__selected : Styles.main_container__behavior_container__item
                  }
                  key={index}
                  onClick={() => handleSelectItem(item)}
                >
                  <TickIcon />
                  {item?.[language]}
                </div>
              ))}
            </div>
          ) : (
            <Container direction={direction} width={['100%', '100%', '100%', '70%', '50%']} px={[2]} gap={15} flexDirection="column">
              <Text variant="heading1" color="typePurpure.300" textAlign="center">
                {t('add_child:welcomeSection:title')}
              </Text>
              <Text fontSize={[18, 18, 20, 23]} lineHeight={1.2} textAlign="center">
                {t('add_child:welcomeSection:description')}
              </Text>
            </Container>
          ))}

        {isVariantA && addChildStep === 2 ? (
          <Button onClick={variantANextHandler} width={[120]}>
            {t('add_child:welcomeSection:next')}
          </Button>
        ) : (
          <div className={Styles.main_container__footer} style={{ flexDirection: language === 'en' ? 'row' : 'row-reverse' }}>
            <Button
              disabled={disableGoBack}
              variant="ghost"
              fontSize={17}
              onClick={handleGoBack}
              className={Styles.main_container__footer__back}
              style={{ flexDirection: language === 'en' ? 'row' : 'row-reverse' }}
            >
              {language === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              <p>{t('add_child:footer:back')}</p>
            </Button>
            <Button
              variant="ghost"
              disabled={disableNext}
              fontSize={17}
              onClick={() => {
                if (isVariantA && addChildStep !== 1) {
                  handleSubmitChildInformation()
                } else {
                  handleSubmit(handleSubmitChildInformation)()
                }
              }}
              style={{ flexDirection: language === 'en' ? 'row' : 'row-reverse' }}
              className={handleCheckIsValid() ? Styles.main_container__next : Styles.main_container__next__disabled}
            >
              <p>{t('add_child:footer:next')}</p>
              {language === 'en' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            </Button>
          </div>
        )}
      </div>
    </Container>
  )
}
