// Actions
import { setLoading, setError, clearError, setModalLoading } from '../reducers/generalSlice'
import {
  setCurrentParent,
  setChildInformation,
  setQuestions,
  setAllApplies,
  setOneApply,
  setAllTherapists,
  setOneTherapist,
  setAssignings,
  setParentForAssigning,
} from '../reducers/advisorOnbordingSlice'
// Api call
import { advisorOnboardingApi } from '../api'

export const advisorOnbordingActions = {
  setParent: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      dispatch(setCurrentParent(data))
      dispatch(clearError())
      dispatch(setLoading(false))
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  setChildInfo: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      dispatch(setChildInformation(data))
      dispatch(clearError())
      dispatch(setLoading(false))
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  setQuestions: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      dispatch(setQuestions(data))
      dispatch(clearError())
      dispatch(setLoading(false))
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  changeStatusApplies: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      advisorOnboardingApi
        .changeStatusApply(data)
        .then((response) => {
          dispatch(setLoading(false))
        })
        .catch(() => {
          // console.log('advisorOnboardingActions.changeStatusApply', error)
        })
    } catch (error) {
      // console.log('advisorOnboardingActions.changeStatusApply', error)
    }
  },
  getAllApplies: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      advisorOnboardingApi
        .getAllApplies(data)
        .then((res) => {
          dispatch(setAllApplies(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          dispatch(setLoading(false))
          // console.log('advisorOnboardingApi.getAllApplies', error)
        })
    } catch (error) {
      dispatch(setLoading(false))
      // console.log('advisorOnboardingActions.getAllApplies', error)
    }
  },
  getOneApply: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      advisorOnboardingApi
        .getResults(data)
        .then((res) => {
          dispatch(setOneApply(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('advisorOnboardingApi.getOneApply', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('advisorOnboardingActions.getOneApply', error)
      dispatch(setLoading(false))
    }
  },
  submitParentApply: (data) => (dispatch) => {
    dispatch(setLoading(true))

    return new Promise((resolve, reject) => {
      advisorOnboardingApi
        .submitApply(data)
        .then((res) => {
          dispatch(setLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  declineParentApply: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      advisorOnboardingApi
        .declineApply(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  getAllTherapists: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      advisorOnboardingApi
        .getAllTherapists(data)
        .then((res) => {
          dispatch(setAllTherapists(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('advisorOnboardingApi.getAllTherapists', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('advisorOnboardingActions.getAllTherapists', error)
      dispatch(setLoading(false))
    }
  },
  getAllParentsForAssignings: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      advisorOnboardingApi
        .getAllParentsForAssign(data)
        .then((res) => {
          dispatch(setAssignings(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('advisorOnboardingApi.getAllParentsForAssignings', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('advisorOnboardingActions.getAllParentsForAssignings', error)
      dispatch(setLoading(false))
    }
  },
  getParentInfoForAssigning: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      advisorOnboardingApi
        .getParentInfoForAssign(data)
        .then((res) => {
          dispatch(setCurrentParent(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('advisorOnboardingApi.getParentInfoForAssign', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('advisorOnboardingActions.getParentInfoForAssigning', error)
      dispatch(setLoading(false))
    }
  },
  setCurrentTherapist: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      dispatch(setOneTherapist(data))
      dispatch(clearError())
      dispatch(setLoading(false))
    } catch {
      dispatch(setLoading(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  clearOnboardingDetails: () => (dispatch) => {
    try {
      dispatch(setChildInformation({}))
      dispatch(setQuestions([]))
    } catch (e) {
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  assignTherapists: (data) => (dispatch) => {
    dispatch(setLoading(true))

    return new Promise((resolve, reject) => {
      advisorOnboardingApi
        .assignTherapists(data)
        .then((res) => {
          dispatch(setLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  getProfile: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      advisorOnboardingApi
        .getProfile(data)
        .then((res) => {
          dispatch(setCurrentParent(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          // console.log('advisorOnboardingApi.getProfile', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('advisorOnboardingActions.getProfile', error)
      dispatch(setLoading(false))
    }
  },
}
