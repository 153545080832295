import { Button, Container, Text } from 'components/Core'
import { translateParentalWellbeing, translateQuestionnaire, translateSubAnswersQuestionnaire } from 'data/locale.data'
import type { LanguageKeys } from 'utils/types/types'
import type { SubStatementAnswerPayload } from './slice/types'

type Props = {
  id: number
  statement: string
  sub_statement_answer: string
  subStatementIndex: number
  language: string
  setSubStatementAnswer: (payload: SubStatementAnswerPayload) => void
  increaseSubStatementIndex: () => void
  mainStatementIndex: number
}

export const SubStatement = ({ mainStatementIndex, subStatementIndex, statement, sub_statement_answer, language, setSubStatementAnswer, increaseSubStatementIndex }: Props) => {
  return (
    <Container width="100%" px={2} gap={16} flexDirection="column">
      {/* <Text variant="numberHolder">{`${mainStatementIndex + 1}.${subStatementIndex + 1}`}</Text> */}
      <Text variant="heading4" textAlign="center" color="black.600">
        {/* @ts-ignore */}
        {translateQuestionnaire?.[mainStatementIndex as keyof typeof translateQuestionnaire]?.[statement]?.[language]}
      </Text>
      <Container width="100%" gap={10} flexDirection="column">
        {Object.values(translateSubAnswersQuestionnaire['en']).map((item) => (
          <Button
            onClick={() => {
              setSubStatementAnswer({
                subStatementAnswer: item,
                subStatementIndex,
                mainStatementIndex,
              })
              increaseSubStatementIndex()
            }}
            width="100%"
            key={item}
            variant={sub_statement_answer === item ? 'primary' : 'secondary'}
          >
            {translateParentalWellbeing[item as keyof typeof translateParentalWellbeing][(language ?? 'en') as LanguageKeys]}
          </Button>
        ))}
      </Container>
    </Container>
  )
}
